import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  click:any='clients'
  popUp:any
  token:any
  selectedDate:any
  storedData:any={}
  
  workLogArray:any={}
  
key:any
  constructor(public router:Router,private loginService:LoginService) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    this.storedData = JSON.parse(localStorage.getItem("user") || '{}');
    // this.listAttendance()

  }
  logOut(){

    let storedData = JSON.parse(localStorage.getItem("user") || '{}');


let data = {
  "userId": storedData._id || null
};
    this.loginService.logOut(data,this.token).subscribe((response: any) => {
      if (response.status == 1) {

        this.router.navigate(["/login"])



        }
      })



this.router.navigate(["/login"])

  }
  profile(){
    this.router.navigate(["/profile"])

  }

  listAttendance() {
      const today = new Date();
      const year = today.getFullYear();
      const month = ('0' + (today.getMonth() + 1)).slice(-2); // Add leading zero if needed
      const day = ('0' + today.getDate()).slice(-2); // Add leading zero if needed
      const todayString = `${year}-${month}-${day}`;
  
      // Set the date input value to today's date
      this.selectedDate = todayString;
  
     let data= {date:this.selectedDate,
      userId:this.storedData._id
    }
    this.loginService.listAttendance(data, this.token)
    .subscribe({
      next: (response: any) => {
        console.log(response);
  
        if (response.status === 1) {
          this.workLogArray = response.attendance;
  
         if(this.workLogArray.punchInTime){
                  this.key='punchOut'
         }}
      },
      error: (error) => {
        if (error.status === 500) {
          console.log("Internal Server Error (500)");
          
         
          // this.punchinFlag = true;
        } else {
          console.error("An error occurred:", error);
        }
      }
    });
  
    }









    get showPunchIn(): boolean {
      return !this.workLogArray.punchInTime || !this.workLogArray.punchOutTime;
    }
    
    get showPunchOut(): boolean {
      return !!this.workLogArray.punchInTime && !this.workLogArray.punchOutTime;
    }








  route(){
    this.listAttendance()
    if(this.click=='agency'
    ){
      this.router.navigate(["/agency"])

    }
    else   if(this.click=='products'
    ){
      this.router.navigate(["/product-list"])

    }
    else   if(this.click=='dashboard'
    ){
      this.router.navigate(["/main-dashboard"])

    }
     else  if(this.click=='staff'
    ){
      this.router.navigate(["/staff-list"])

    }

    else  if(this.click=='clients'
    ){
      this.router.navigate(["/work-page"])

    }
    else  if(this.click=='report'
    ){
      this.router.navigate(["/report"])

    }
    else  if(this.click=='disable'
    ){
      this.router.navigate(["/disable-page"])

    }
    else if (this.click=='fields'){
      this.router.navigate(["/dynamic-field"])


    }
    else if(this.click=='rejected'){
      this.router.navigate(["/rejected-page"])

    }
    else if(this.click=='reminder'){

      this.router.navigate(["/reminder-page"])

    }
  }

}
