<div class="main-wrapper">
    <div class="main-content">
        <header>

            <div class="head-line">

                <p>Works</p>
                

            </div>
            <div class="user-wrapper">
                <p>{{storedData.agencyRefId.agencyName}}</p>

                <div>
                    <img class="log1" [src]="this.storedData.agencyRefId.logo?this.storedData.agencyRefId.logo:'../../../../assets/../../assets/images/user1.jpeg'">
                </div>
            </div>
        </header>
        <main class="main-2"  *ngIf="punchinFlag">
        

            <div class="container">
             <div class="projects">
                 
                 
                 <!-- <div class="two-screen"> -->
<


                     <!-- punchin -->

                     <div class="card2"  >
                     
                     
                        <div class="main-2side">

                            <div class="no-img">
                               <img src="../../assets/images/Frame 427319202 (2).jpg">
                               <h4>
                                <span>Hi,</span>  
                              {{storedData.name}}</h4>
                               <p>Time to kick off your day! 
                                </p>
                               <p>Please punch in to start your work.</p>
                               <button (click)="punchinFlag=false;punchInUploadFlag=true;"><img src="../../assets/images/punchin.svg"><span>
                                {{workLogListFlag?'PunchIn':'PunchOut'}}
                               </span></button>
                            
                            </div>
                            </div>
                            </div>
                             
                           
    <!-- </div>                  -->
              
                 </div>
                
         </div>

 
         </main>

         
         <main class="main-2" *ngIf="punchInUploadFlag">

            <div class="container">
             <div class="projects">
                 
                 
                 <div class="two-screen">
<!---sec sidebar-->

<div class="main-side">

 <div class="card21" id="card-12">
      
     
<div class="card-new">
     <div class="agency-head" [attr.style]="'background-image: url(' + (this.storedData.coverImage ? this.storedData.coverImage : '../../assets/images/pic1.jpg') + '); background-size: cover;'">
         <img [src]="this.storedData.photo?this.storedData.photo:'../../../../assets/../../assets/images/user1.jpeg'">
         <div>
             <p>{{ this.storedData.name}}</p>
             <span>Field executive</span>
         </div>
      
   
    </div>      

     <div class="img-up">

       <div class="img-selfie">  <img style="width: 152px;height: 166px;" [src]="this.punchInForm.value.punchInSelfie?this.punchInForm.value.punchInSelfie:'../../assets/images/punchin-img.png'"></div>
         <p>Please take a selfie</p>
         <div class="btns-upload">
             <div class="btns-upload">
                 <label for="file-upload" class="custom-file-upload">
                     <img src="../../assets/images/upload (1).png" alt="Upload Icon">
                     <span>Upload image</span>
                 </label>
                 <input type="file" id="file-upload"  (change)="upload('selfie',$event)"/>
             </div>

             <!-- <button><img src="images/upload.png"><span>Upload image</span></button> -->
             <button (click)="cameraFlag=true;    this.startCamera();
             "><img src="../../assets/images/camera.png"><span>Open camera</span></button>
         </div>


     </div>
     <form class="staff-form"  [formGroup]="punchInForm">
         <div class="row">
             <div class="col-lg-12 col-xs-12 col-sm-6">
         <div class="form-group">
           <label>Remarks*</label>
           <textarea class="form-control" placeholder="Type here"   formControlName="punchInRemarks" style="height: 99px;"></textarea>
         </div>
         </div>
         </div>
         </form>

         <div class="content">
             <button class="add-n" (click)="punchInUploadFlag=false;workLogListFlag=true">Cancel</button>
             <!---->
             <button class="btn-two" (click)="addPunchIn()">Submit</button>
         </div>
</div>
 
 </div>


  <div class="footer-section2">

     <img src="../../assets/images/accruon-footer (2).png">
  </div>
 
 </div>



<!---------------->
              
                     </div>                 
              
                 </div>
         </div>

 
         </main>



<!---------------->
                 
                  

        </div>
      
        </div>



        <div id="" class="overlay" *ngIf="cameraFlag">
            <div class="popup-ins" id="popup-ins1">
        <div>
        <video #video width="640" height="480" autoplay></video>
        <!-- <button class="btn-sec">Take Photo</button> -->
        <canvas #canvas width="640" height="480" style="display:none;"></canvas>
        <img [src]="photo" *ngIf="photo" />
        </div>
                <div class="ins-footer">
            <button class="btn-sec" (click)="cameraFlag=false;this.stopCamera()" >Back</button>
            <button class="btn-prime" (click)="cameraFlag=false;takePhoto()">Take Photo</button>
            </div>
          
            </div>
          </div> 




          

    <!----popup- client selection-->

 <div id="add-enquiry" class="overlay" *ngIf="clientselectionFlag">
    <div class="popup-ins" id="popup-ins1">
        <div class="ins-c" id="ins-c1">
       <div>
            <h2>Client Selection</h2>
            <p class="table-h1">Please select a client for add work</p>
            <div class="">
				<input type="text" id="searchQuery-Input" placeholder="Search" value="" [(ngModel)]="searchQuery">
				<button type="submit" id="searchQuerySubmit">
					<svg viewBox="0 0 24 24" style="width: 24px; height: 24px;">
						<path fill="#666666" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"></path>
					</svg>
				</button>
			</div>
        </div>
            <div class="staff-list">
                <div class="format-one" *ngFor="let each of cientDetails | appFilter:searchQuery">
                    <div class="format-two">
                    <input type="radio" class="select-check"   (change)="toggleClientSelection(each, $event)">
                    
                    <img   [src]="each.clientLogo?each.clientLogo:'../../../../assets/images/user1.jpeg'">
                    <div class="three-line">
                    <h6>{{each.clientName}}</h6>
                    <p>{{each.clientAddress}}</p>
                </div>
                </div>
                <div class="format-one" id="format-1">
                    <button >Open</button>
                </div>
                </div>

               
            </div>
    </div>

        <div class="ins-footer">
    <button class="btn-sec">Cancel</button>
    <button class="btn-prime" (click)="openClient()">Continue</button>
    </div>
  
    </div>
  </div> 
   <!--Popup end-->







   
    <!----popup- Product selection-->


 <!-- <div id="add-enquiry" class="overlay">
    <div class="popup-ins" id="popup-ins1">
        <div class="ins-c" id="ins-c1">
       <div>
            <h2>Product selection</h2>
          <div class="staff-list3">
            <div class="format-one">
                <div class=" f-one">
                <div class="format-two">
                    <img src="images/Frame 427319202.jpg"></div>

                <div class="three-line">
                <h6>LULU Mall</h6>
                <p>Edappalli, Ernakulam</p>
            </div>
            </div>
            <div class="format-one" id="format-1">
                <button>Open</button>
            </div>
            </div>
        </div>

            <p class="table-h1">Please select the products</p>
            <div class="">
				<input type="text" id="searchQuery-Input" placeholder="Search" value="">
				<button type="submit" id="searchQuerySubmit">
					<svg viewBox="0 0 24 24" style="width: 24px; height: 24px;">
						<path fill="#666666" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"></path>
					</svg>
				</button>
			</div>
        </div>


            <div class="staff-list2">
                <div class="format-one">
                    <div class="format-two">
                    <input type="checkbox" class="select-check">
                    <img src="images/Frame 427319202.jpg">
                    <div class="three-line">
                    <h6>LULU Mall</h6>
                    <p>Edappalli, Ernakulam</p>
                </div>
                </div>
                </div>
                <div class="format-one">
                    <div class="format-two">
                    <input type="checkbox" class="select-check">
                    <img src="images/Frame 427319202.jpg">
                    <div class="three-line">
                    <h6>LULU Mall</h6>
                    <p>Edappalli, Ernakulam</p>
                </div>
                </div>
           
                </div>

                <div class="format-one">
                    <div class="format-two">
                    <input type="checkbox" class="select-check">
                    <img src="images/Frame 427319202.jpg">
                    <div class="three-line">
                    <h6>LULU Mall</h6>
                    <p>Edappalli, Ernakulam</p>
                </div>
                </div>
               
                </div>
            </div>
    </div>
        <div class="ins-footer">
    <button class="btn-sec">Cancel</button>
    <button class="btn-prime">Continue</button>
    </div>
  
    </div>
  </div>
    -->
   <!--Popup end-->


