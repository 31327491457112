<body>

    <div class="main-wrapper">
        <div class="main-content">
            <header>

                <div class="head-line">
    
                    <p>Client List</p>
                </div>
                <div class="user-wrapper">
                    <p>{{storedData.agencyRefId.agencyName}}</p>
    
                    <div>
                        <img class="log1" [src]="this.storedData.agencyRefId.logo?this.storedData.agencyRefId.logo:'../../../../assets/images/user1.jpeg'">
                    </div>
                </div>
            </header>
    
          <main class="main-2">
    
            <div class="container">
              <div class="projects">
    
    
                <div class="two-screen">
                  <!---sec sidebar-->
    
    
                  <div class="main-side">
                    <div class="agency-head" id="agency-h1">
    
                      <input type="file" id="fileInput" class="file-input-bg-edit" (change)="upload('clientLogo',$event)">
                      <label for="fileInput" class="file-label">
                        <img alt="User Image"  (click)="ImageOpen(clientForm.value.clientLogo)" style="cursor: pointer;    object-fit: cover;"
                         src="{{clientForm.value.clientLogo ? clientForm.value.clientLogo:'../../../../assets/images/user-no.jpg'}}">
                        <img src="../../assets/images/plus-black.png" class="plus-b"></label>
    
    
                      <div>
                        <button class="stat-btn"  >
                          <span *ngIf="this.workData.flag=='ADD'">Fresh case</span>

                          <span *ngIf="this.workData.flag=='EDIT'">{{this.workData.status}}</span>
                        </button>
    
                      </div>
                    </div>
    
    
                    <form class="staff-form"  [formGroup]="clientForm" *ngIf=" this.workData.flag=='ADD'">
    
                      <div class="row">
                        <div class="col-lg-6 col-xs-12 col-sm-6">
                          <div class="form-group">
                            <label>Ref. ID*</label>
                            <input type="text" class="form-control" formControlName="clientRefId" readonly >
                          </div>
                        </div>
                        <div class="col-lg-6 col-xs-12 col-sm-6">
                          <div class="form-group">
                            <label>Initiated date*</label>
                            <input type="date" class="form-control" formControlName="initiatedDate" readonly  placeholder="Type here">
                          </div>
                        </div>
                        <div class="col-lg-6 col-xs-12 col-sm-6">
                          <div class="form-group">
                            <label>Initiated by*</label>
                            <input type="text" class="form-control" formControlName="initiatedBy" readonly  placeholder="Type here">
                          </div>
                        </div>
                        <div class="col-lg-6 col-xs-12 col-sm-6">
                          <div class="form-group">
                            <label>Updated date*</label>
                            <input type="date" class="form-control" formControlName="updatedDate" readonly   placeholder="Type here">
                          </div>
                        </div>
                        <!-- <div class="col-lg-6 col-xs-12 col-sm-6">
                          <div class="form-group">
                            <label>Allocated to*</label>
                            <input type="email" class="form-control"   formControlName="allocatedTo"  readonly  placeholder="Type here">
                          </div>
                        </div> -->
                        <div class="col-lg-6 col-xs-12 col-sm-6">
    
                          <div class="form-group" style="display: grid;">
                            <label>Country*</label>
                            <!-- <input type="email" class="form-control"   formControlName="clientCountry"  [(ngModel)]="this.workData.clientCountry" placeholder="Type here"> -->

                            <select class="select-al"  formControlName="clientCountry" >
                              <!-- <option disabled>Select Counrty</option> -->
                              <!-- <option value="india">India</option> -->
                              <option value="UAE">UAE</option>
                              <option value="Other">Other</option>

                            </select>
                          </div>
                        </div>
    
                        <div class="col-lg-12 col-xs-12 col-sm-6">
                          <div class="form-group">
                            <label>Company name</label>
                            <input type="email" class="form-control"   formControlName="clientName" placeholder="Type here">
                          
                            <p *ngIf="submitted&&!clientForm.value.clientName" style="color: red;">Required</p>
                          </div>
                        </div>
                        <div class="content">
                          <button class="add-n" (click)="check()">Check Exist</button>
                     </div>

                        
                        <div class="col-lg-12 col-xs-12 col-sm-6">
                          <div class="form-group">
                            <label>Product</label>

                            <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="productDetails"  (onSelect)="onDropdownClose()" formControlName="products" >
                          </ng-multiselect-dropdown>
                                                    </div>
                        </div>
    
<!-- Dynamic fields -->


<!-- Dynamic fields  ends -->



















                        <div class="col-lg-12 col-xs-12 col-sm-6">
                          <div class="form-group">
                            <label>Company address*</label>
                            <textarea class="form-control" placeholder="Type here"  formControlName="clientAddress"  style="height: 167px;"></textarea>
    
                          </div>
                        </div>
                        <div class="col-lg-12 col-xs-12 col-sm-6">
                          <div class="form-group">
                            <div *ngFor="let question of this.clientForm.value.clientQuestion" class="form-group">
                              <label>{{ question.displayName }} </label>
                        
                              <ng-container [ngSwitch]="question.type">
                                <input 
                                *ngSwitchCase="'text'" 
                                type="text" 
                                class="form-control user-in" 
                                [(ngModel)]="question.value" 
                                [name]="question.field"
                                [required]="question.required" 
                                [maxlength]="question.characterLimit"
                                [ngModelOptions]="{standalone:true}"
                                (ngModelChange)="checkTextLength(question)" 
                              />
                              <div *ngIf="question.required && !question.value&&submitted&&question.type=='text'" class="text-danger"> 
                                 This field is required.
                              </div>
                              <div *ngIf="question.characterLimit && question.value?.length > question.characterLimit&&submitted&&question.type=='text'" class="text-danger">
                                Maximum {{ question.characterLimit }} characters allowed.
                              </div> 
                            
                              <!-- Number Input -->
                              <input 
                                *ngSwitchCase="'number'" 
                                type="number" 
                                class="form-control user-in" 
                                [(ngModel)]="question.value" 
                                [name]="question.field"
                                [required]="question.required"
                                [ngModelOptions]="{standalone:true}" 
                              />
                               <div *ngIf="question.required && !question.value&&submitted&&question.type=='number'" class="text-danger">
                                This field is required.
                              </div> 
                            
                              <!-- Month Input -->
                              <input 
                                *ngSwitchCase="'month'" 
                                type="month" 
                                class="form-control user-in" 
                                [(ngModel)]="question.value" 
                                [name]="question.field"
                                [required]="question.required"
                                [ngModelOptions]="{standalone:true}" 
                              />
                              <div *ngIf="question.required && !question.value&&submitted&&question.type=='month'" class="text-danger">
                                This field is required.
                              </div> 
                            
                              <!-- Time Input -->
                              <input 
                                *ngSwitchCase="'time'" 
                                type="time" 
                                class="form-control user-in" 
                                [(ngModel)]="question.value" 
                                [name]="question.field"
                                [required]="question.required"
                                [ngModelOptions]="{standalone:true}" 
                              />
                               <div *ngIf="question.required && !question.value&&submitted&&question.type=='time'" class="text-danger">
                                This field is required.
                              </div>
                            
                              <!-- Date Input -->
                              <input 
                                *ngSwitchCase="'date'" 
                                type="date" 
                                class="form-control user-in" 
                                [(ngModel)]="question.value" 
                                [name]="question.field"
                                [required]="question.required"
                                [ngModelOptions]="{standalone:true}" 
                              />
                              <div *ngIf="question.required && !question.value&&submitted&&question.type=='date'" class="text-danger">
                                This field is required.
                              </div>
                            
                              <!-- TextCaps Input with Character Limit -->
                              <input 
                                *ngSwitchCase="'textcaps'" 
                                type="text" 
                                class="form-control user-in" 
                                style=" text-transform: uppercase;" 
                                [(ngModel)]="question.value" 
                                [name]="question.field"
                                [required]="question.required"
                                [maxlength]="question.characterLimit"
                                [ngModelOptions]="{standalone:true}" 
                                (input)="question.value = question.value.toUpperCase()" 
                              />
                              <div *ngIf="question.required && !question.value&&submitted&&question.type=='textcaps'" class="text-danger">
                                This field is required.
                              </div>
                              <!-- <div *ngIf="question.characterLimit && question.value?.length > question.characterLimit&&submitted&&question.type=='textcaps'" class="text-danger">
                                Maximum {{ question.characterLimit }} characters allowed.
                              </div> -->















                        
                                <!-- Select Input with Dynamic Options -->
                                <select 
                                  *ngSwitchCase="'select'" 
                                  class="form-control " 
                                  [(ngModel)]="question.value" 
                                  [ngModelOptions]="{standalone:true}"
                                  [name]="question.field">
                                  <option 
                                    *ngFor="let option of question.valueList || []" 
                                    [value]="option">
                                    {{ option }}
                                  </option>
                                </select>


                                <div *ngIf="(question.value === 'other'||question.value === 'Other'||question.value === 'OTHER') && question.type === 'select'&&question.type=='select'" class="mt-2">
                                  <input
                                    type="text"
                                    class="form-control"
                                    [(ngModel)]="question.otherValue"
                                    [ngModelOptions]="{standalone: true}"
                                    placeholder="Enter new value"
                                   
                                  />
                                
                                </div>

                                <div *ngIf="question.required && !question.value&&submitted&&question.type=='select'" class="text-danger">
                                  This field is required.
                                </div> 
                              </ng-container>
                            </div>
                          </div>
                        </div>
                        
    
                        <p class="blue-head">Validations</p>
    
                        <div class="col-lg-12 col-xs-12 col-sm-10 bd-1">
                          <div class="sm-1">
                            <div class="form-group">
                              <label>Company website*</label>
                              <input type="text" class="form-control"   formControlName="companyWebsite"   placeholder="Type here">
                            </div>
                            <div (click)="windowOpen(clientForm.value.companyWebsite,'google')">
                              <img src="../../assets/images/devicon_google.png" class="img-icon">
                            </div>
                            <div (click)="windowOpen(clientForm.value.companyWebsite,'site')">
                              <img src="../../assets/images/mdi_internet.png" class="img-icon">
                            </div>
                          </div>
                        </div>
                     

                        <div class="col-lg-12 col-xs-12 col-sm-10 bd-1">
                          <div class="sm-1">
                            <div class="form-group">
                              <label class="green-head ">Email*</label>
                              <!-- <input type="text" class="form-control" placeholder="Type here"> -->
                            </div>
                            <div (click)="addEmail()">
                                <div class="btn-plus"><img src="../../assets/images/plus.png"></div>
                              </div>
                          </div>
                        </div>
                        <div class="col-lg-10 col-xs-12 col-sm-10 bd-1"  formArrayName="email">
                          <div class="sm-1" *ngFor="let securityDetail of clientEmail.controls; let i=index"  >
                            <div class="form-group">
                              <input 
                                type="text" 
                                [formControlName]="i" 
                                class="form-control" 
                                placeholder="Type here" (input)="test()"
                              />
                              <!-- <div *ngIf="clientFormControl.status=='INVALID' && (clientFormControl.touched || submitted)" style="color: red;font-size: 12px;" >
                              <p *ngIf="isPatternError(clientFormControl.controls[i])" style="color: red;">Invalid Email</p>
                              </div>
                             -->
                            </div>
                           
                            <!-- <div>
                              
                              <button><img src="../../assets/images/icon.png">
                                <p>Verify</p>

                              </button>
                              
                            </div> -->
                            <button style="    margin-left: 59px;background: white;"  (click)="DeleteEmail(i)"><img src="../../assets/images/Delete.png"></button>

                          </div>
                        </div>
    
                        <!-- <div class="col-lg-12 col-xs-12 col-sm-10 bd-1">
                          <div class="sm-2">
                            <div class="form-group">
    
                              <input type="text" class="form-control" placeholder="Type here">
                            </div>
                           
                            <div>
                              <button><img src="../../assets/images/icon.png">
                                <p>Verify</p>
                              </button>
                            </div>
    
                          </div>
                        </div> -->
                        <div class="col-lg-12 col-xs-12 col-sm-10 bd-1">

                            <div class="sm-1">
                                <div class="form-group">
                                    </div>
                            </div>
                        </div>
                      
                        <div class="col-lg-12 col-xs-12 col-sm-10 bd-1">
                            <div class="sm-1">
                              <div class="form-group">
                                <label class="green-head ">Whatsapp contact</label>
                                <!-- <input type="text" class="form-control" placeholder="Type here"> -->
                              </div>
                              <div (click)="addWhatsapp()">
                                  <div class="btn-plus"><img src="../../assets/images/plus.png"></div>
                                </div>
                            </div>
                          </div>
    <div formArrayName="whatsAppContact">
        <div *ngFor="let securityDetail of clientWhatsAppDetails.controls; let i=index" class="row" [formGroupName]="i">
                        <div class="col-lg-3 col-xs-12 col-sm-6" >
                          <div class="form-group">
                            <label>Name*</label>
                            <input type="email" class="form-control"      formControlName="name" placeholder="Type here">
                          </div>
                        </div>
    
                        <div class="col-lg-3 col-xs-12 col-sm-6">
                          <div class="form-group">
                            <label>Designation*</label>
                            <input type="email" class="form-control"  formControlName="designation" placeholder="Type here">
                          </div>
                        </div>
                        <div class="col-lg-3 col-xs-12 col-sm-10 bd-1">
                          <div class="sm-1">
                            <div class="form-group">
                              <label>Number*</label>
                              <input type="text" class="form-control" ng2TelInput
                              [ng2TelInputOptions]="{initialCountry: 'ae'}"  (intlTelInputObject)="telInputObject($event)"
                              formControlName="whatsappNumber" placeholder="Type here">
                              <!-- <div *ngIf="whatsAppContact.status=='INVALID' && (whatsAppContact.touched || submitted)" style="color: red;font-size: 12px;" >
                                <p *ngIf="isPatternError1(whatsAppContact.controls[i])" style="color: red;">Invalid </p>
                                </div> -->
                              
                            </div>
                            <!-- <div>
                              <button><img src="../../assets/images/icon.png">
                                <p>Verify</p>
                              </button>
                            </div> -->
                            <button style="    margin-left: 59px;background: white;"           (click)="DeleteWhatsapp(i)"><img src="../../assets/images/Delete.png">

                            </button>
                          </div>
                        </div>
                        </div>
                        </div>
    
                        
    
                        <div class="col-lg-8 col-xs-12 col-sm-6 ext-padding">
                          <div class="col-lg-12 col-xs-12 col-sm-6">
                            <label class="mb-0">Add location</label>
                            <table class="agency-table mt-2" id="ag-t1">
                              <tbody>
                                <tr>
                                  <td class="dot-width">
                                    <img src="../../assets/images/google-map.png">
                                  </td>
                                  <td>
    
                                    <div>
                                      <p class="name-two">Location</p>
                                      <p class="name-one">{{this.clientForm.value.clientGMap.address}}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="content">
                                      <button class="btn-two" (click)="showMaps()">Add Google Map</button>
                                    </div>
                                  </td>
                                </tr>
    
                              </tbody>
                            </table>
                          </div>
    
                          <div class="col-lg-12 col-xs-12 col-sm-6">
                            <label>Photos*</label>
                            <div class="form-group img-slide mt-0">
                                <div class="image-upload">
                                    <input type="file" id="fileInputClient" class="file-input-bg-edit"
                                        (change)="upload('clientPhotos',$event)">
                                    <label for="fileInputClient" class="file-label">
                                        <img alt="User Image" src="../../assets/images/no-img.jpg"
                                            class="user-img">
                                        <img src="../../assets/images/plus-black.png" class="plus-img">
                                    </label>
                                </div>
                                <div *ngFor="let data of productArray" style="cursor: pointer;">
                                    <img [src]="data" style="width: 70px; height: 79px;" (click)="ImageOpen(data)">
                                </div>
    
    
                            </div>
                          </div>
    
                        </div>
    
    
                        <div class="col-lg-4 col-xs-12 col-sm-6">
                          <div>
    
                            <!-- <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="14" style="width: 100%; height: 323px; border: 0px;">
                              <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true" (dragEnd)="markerDragEndBranch($event)">
                    
                              </agm-marker>
                          </agm-map>   -->
                          </div>
                        </div>
    
    
                        <!-- <div class="table-container mt-4">
                          <table class="table-record">
                            <thead>
                              <tr>
                                <th style="width: 7%;">No.</th>
                                <th style="width: 15%;">Date</th>
                                <th style="width: 60%;">Text</th>
                                <th style="width: 49%;" class="right-align">Call recordings</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div class="bd-in">01.</div>
                                </td>
                                <td>
                                  <div class="bd-in">04/11/2024</div>
                                </td>
                                <td>
                                  <div class="bd-in">It is a long established fact that a reader will be distracted by the
                                    readable content of a page when looking at its layout...</div>
                                </td>
                                <td class="call-recordings">
                                  <div class="audio-one">
                                    <audio controls="">
                                      <source src="" type="audio/ogg">
                                    </audio>
                                    <div class="del-img">
                                      <img src="../../assets/images/ph-vector.png">
                                    </div>
                                  </div>
    
                                  <div class="audio-one">
                                    <audio controls="">
                                      <source src="" type="audio/ogg">
                                    </audio>
                                    <div class="del-img">
                                      <img src="../../assets/images/ph-vector.png">
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="4" class="right-align" style="border-top: 1px solid #fff !important;">
                                  <button class="btn-plus"><img src="../../assets/images/plus.png"></button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
    
    
                        <div class="remark-div">
                          <button class="add-remark"><img src="../../assets/images/plus-blue.svg">
                            <p>Add remarks</p>
                          </button>
                        </div>
     -->
    
    
                      </div>
                    </form>
                    <form class="staff-form"  [formGroup]="clientForm" *ngIf=" this.workData.flag=='EDIT'">
    
                      <div class="row">
                        <div class="col-lg-6 col-xs-12 col-sm-6">
                          <div class="form-group">
                            <label>Ref. ID*</label>
                            <input type="text" class="form-control" formControlName="clientRefId"  readonly [(ngModel)]="this.workData.clientRefId" placeholder="Type here">
                          </div>
                        </div>
                        <div class="col-lg-6 col-xs-12 col-sm-6">
                          <div class="form-group">
                            <label>Initiated date*</label>
                            <input type="text" class="form-control" readonly  [value]="formatDate(workData.createdAt)"placeholder="Type here">
                          </div>
                        </div>
                        <div class="col-lg-6 col-xs-12 col-sm-6">
                          <div class="form-group">
                            <label>Initiated by*</label>
                            <input type="text" class="form-control" formControlName="initiatedBy"  readonly   [(ngModel)]="this.workData.initiatedBy.name"  placeholder="Type here">
                          </div>
                        </div>
                        <div class="col-lg-6 col-xs-12 col-sm-6">
                          <div class="form-group">
                            <label>Updated date*</label>
                            <input type="text" class="form-control" readonly  [value]="formatDate(workData.updatedAt)" placeholder="Type here">
                          </div>
                        </div>                        <div class="col-lg-6 col-xs-12 col-sm-6">
                          <div class="form-group"  *ngIf="workData.status=='New case'" >
                            <label>Allocated to</label>
      
                            <select class="select-al" 
                            formControlName="allocatedTo"  (change)="AllocateStaff()">
                              <option *ngFor="let staff of this.staffs"  [value]="staff._id">
                                {{staff.name}}
                              </option>
                           
                            </select> 

                          </div>
                          <div class="form-group" *ngIf="workData.status=='Verified'" >
                            <label>Allocated to Telecaller</label>
        
                            <select class="select-al"      [(ngModel)]="this.teleCaller"  [ngModelOptions]="{standalone:true}">
                              <option *ngFor="let staff of this.telleCallers"  [value]="staff._id">
                                {{staff.name}}
                              </option>
                           
                            </select> 

                          </div> 
                        </div>
                        <div class="col-lg-6 col-xs-12 col-sm-6">
    
                          <div class="form-group" style="display: grid;">
                            <label>Country*</label>
                            <input type="email" class="form-control"   formControlName="clientCountry"  [(ngModel)]="this.workData.clientCountry" placeholder="Type here">

                            <!-- <select class="select-al"  formControlName="clientCountry" [(ngModel)]="this.workData.clientCountry">
                              <option disabled>Select Counrty</option>
                              <option value="india">India</option>
                              <option value="uae">UAE</option>
                            </select> -->
                          </div>
                        </div>
    
                        <div class="col-lg-12 col-xs-12 col-sm-6">
                          <div class="form-group">
                            <label>Company name*</label>
                            <input type="email" class="form-control"   formControlName="clientName" readonly [(ngModel)]="this.workData.clientName"  clientCountryplaceholder="Type here">
                          </div>
                        </div>
              
                        <div class="col-lg-12 col-xs-12 col-sm-6">
                          <div class="form-group">
                            <label>Product</label>

                            <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="productDetails"  [(ngModel)]="this.workData.products"  (onSelect)="onDropdownClose()" formControlName="products" >
                          </ng-multiselect-dropdown>
                                                    </div>
                        </div>
    
                        <div class="col-lg-12 col-xs-12 col-sm-6">
                          <div class="form-group">
                            <label>Company address*</label>
                            <textarea class="form-control" placeholder="Type here"  formControlName="clientAddress"  [(ngModel)]="this.workData.clientAddress"   style="height: 167px;"></textarea>
    
                          </div>
                        </div>

                        <div class="col-lg-12 col-xs-12 col-sm-6">
                          <div class="form-group">
                            <div *ngFor="let question of this.workData.clientQuestion" class="form-group">
                              <label>{{ question.displayName }} </label>
                        
                              <ng-container [ngSwitch]="question.type">
                               
                                <input 
                                *ngSwitchCase="'text'" 
                                type="text" 
                                class="form-control user-in" 
                                [(ngModel)]="question.value" 
                                [name]="question.field"
                                [required]="question.required" 
                                [maxlength]="question.characterLimit"
                                [ngModelOptions]="{standalone:true}"
                                (ngModelChange)="checkTextLength(question)" 
                              />
                              <div *ngIf="question.required && !question.value&&submitted&&question.type=='text'" class="text-danger">
                                This field is required.
                              </div>
                               <!-- <div *ngIf="question.characterLimit && question.value?.length > question.characterLimit&&question.type=='text" class="text-danger">
                                Maximum {{ question.characterLimit }} characters allowed.
                              </div> -->
                             
                              <!-- Number Input -->
                              <input 
                                *ngSwitchCase="'number'" 
                                type="number" 
                                class="form-control user-in" 
                              
                                [(ngModel)]="question.value" 
                                [name]="question.field"
                                [required]="question.required"
                                [ngModelOptions]="{standalone:true}" 
                              />
                              <div *ngIf="question.required && !question.value&&submitted&&question.type=='number'" class="text-danger">
                                This field is required.
                              </div>
                            
                              <!-- Month Input -->
                              <input 
                                *ngSwitchCase="'month'" 
                                type="month" 
                                class="form-control user-in" 
                                [(ngModel)]="question.value" 
                                [name]="question.field"
                                [required]="question.required"
                                [ngModelOptions]="{standalone:true}" 
                              />
                              <div *ngIf="question.required && !question.value&&submitted&&question.type=='month'" class="text-danger">
                                This field is required.
                              </div>
                            
                              <!-- Time Input -->
                              <input 
                                *ngSwitchCase="'time'" 
                                type="time" 
                                class="form-control user-in" 
                                [(ngModel)]="question.value" 
                                [name]="question.field"
                                [required]="question.required"
                                [ngModelOptions]="{standalone:true}" 
                              />
                              <div *ngIf="question.required && !question.value&&submitted&&question.type=='time'" class="text-danger">
                                This field is required.
                              </div>
                            
                              <!-- Date Input -->
                              <input 
                                *ngSwitchCase="'date'" 
                                type="date" 
                                class="form-control user-in" 
                                [(ngModel)]="question.value" 
                                [name]="question.field"
                                [required]="question.required"
                                [ngModelOptions]="{standalone:true}" 
                              />
                              <div *ngIf="question.required && !question.value&&submitted&&question.type=='date'" class="text-danger">
                                This field is required.
                              </div>
                            
                              <!-- TextCaps Input with Character Limit -->
                              <input 
                                *ngSwitchCase="'textcaps'" 
                                type="text" 
                                class="form-control user-in" 
                                style=" text-transform: uppercase;" 
                                [(ngModel)]="question.value" 
                                [name]="question.field"
                                [required]="question.required"
                                [maxlength]="question.characterLimit"
                                [ngModelOptions]="{standalone:true}" 
                                (input)="question.value = question.value.toUpperCase()" 
                              />
                               <div *ngIf="question.required && !question.value&&submitted&&submitted&&question.type=='textcaps'" class="text-danger">
                                This field is required.
                              </div>
                              <!-- <div *ngIf="question.characterLimit && question.value?.length > question.characterLimit&&submitted&&question.type=='textcaps" class="text-danger">
                                Maximum {{ question.characterLimit }} characters allowed.
                              </div>  -->















                        
                                <!-- Select Input with Dynamic Options -->
                                <select 
                                  *ngSwitchCase="'select'" 
                                  class="form-control user-in" 
                                  [(ngModel)]="question.value" 
                                  [ngModelOptions]="{standalone:true}"
                                  [name]="question.field">
                                  <option 
                                    *ngFor="let option of question.valueList || []" 
                                    [value]="option">
                                    {{ option }}
                                  </option>
                                </select>
                                <div *ngIf="(question.value === 'other'||question.value === 'Other'||question.value === 'OTHER') && question.type === 'select'" class="mt-2">
                                  <input
                                    type="text"
                                    class="form-control"
                                    [(ngModel)]="question.otherValue"
                                    [ngModelOptions]="{standalone: true}"
                                    placeholder="Enter new value"
                                   
                                  />
                                
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                        
    
                        <p class="blue-head">Validations</p>
    
                        <div class="col-lg-12 col-xs-12 col-sm-10 bd-1">
                          <div class="sm-1">
                            <div class="form-group">
                              <label>Company website*</label>
                              <input type="text" class="form-control"   formControlName="companyWebsite"  [(ngModel)]="this.workData.companyWebsite" placeholder="Type here">
                            </div>
                            <div>
                              <img src="../../assets/images/devicon_google.png" class="img-icon" (click)="windowOpen(this.workData.companyWebsite,'google')">
                            </div>
                            <div>
                              <img src="../../assets/images/mdi_internet.png" class="img-icon" (click)="windowOpen(this.workData.companyWebsite,'site')">
                            </div>
                          </div>
                        </div>
    <br>
    <div class="col-lg-12 col-xs-12 col-sm-10 bd-1">

        <div class="sm-1">
            <div class="form-group">
                </div>
        </div>
    </div>

                        <div class="col-lg-12 col-xs-12 col-sm-10 bd-1">
                          <div class="sm-1">
                            <div class="form-group">
                              <label class="green-head ">Email*</label>
                              <!-- <input type="text" class="form-control" placeholder="Type here"> -->
                            </div>
                            <div (click)="addEmail()">
                                <div class="btn-plus"><img src="../../assets/images/plus.png"></div>
                              </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-xs-12 col-sm-10 bd-1"  formArrayName="email">
                          <div class="sm-1" *ngFor="let securityDetail of clientEmail.controls; let i=index"  >
                            <div class="form-group">
                              <input 
                                type="text" 
                                [formControlName]="i" 
                                class="form-control" 
                                placeholder="Type here" 
                              />


                              



                              <div *ngIf="clientFormControl.status=='INVALID' && (submitted)" style="color: red;font-size: 12px;" >
                                <p *ngIf="isPatternError(clientFormControl.controls[i])" style="color: red;">Invalid Email</p>
                                </div>
                            </div>
                            
                            <div>
                 <button *ngIf="workData.status=='Under Validation'"
   
    [ngStyle]="{ 'background-color': clientFormControl.controls[i].status!='INVALID'&&  this.clientForm.value.email[i] ? 'green' : '' }">
    <p>{{ clientFormControl.controls[i].status!='INVALID'&&this.clientForm.value.email[i] ? 'Verified' : 'Verify' }}</p>
  </button>
                              <button (click)="DeleteEmail(i)"><img src="../../assets/images/Delete.png"></button>
                            </div>
    
                          </div>
                        </div>
    
                        <!-- <div class="col-lg-12 col-xs-12 col-sm-10 bd-1">
                          <div class="sm-2">
                            <div class="form-group">
    
                              <input type="text" class="form-control" placeholder="Type here">
                            </div>
                           
                            <div>
                              <button><img src="../../assets/images/icon.png">
                                <p>Verify</p>
                              </button>
                            </div>
    
                          </div>
                        </div> -->
                        <div class="col-lg-12 col-xs-12 col-sm-10 bd-1">

                            <div class="sm-1">
                                <div class="form-group">
                                    </div>
                            </div>
                        </div>
                      
                        <div class="col-lg-12 col-xs-12 col-sm-10 bd-1">
                            <div class="sm-1">
                              <div class="form-group">
                                <label class="green-head ">Whatsapp contact</label>
                                <!-- <input type="text" class="form-control" placeholder="Type here"> -->
                              </div>
                              <div (click)="addWhatsapp()">
                                  <div class="btn-plus"><img src="../../assets/images/plus.png"></div>
                                </div>
                            </div>
                          </div>
    <div formArrayName="whatsAppContact">
        <div *ngFor="let securityDetail of clientWhatsAppDetails.controls; let i=index" class="row" [formGroupName]="i">
                        <div class="col-lg-3 col-xs-12 col-sm-5" >
                          <div class="form-group">
                            <label>Name*</label>
                            <input type="email" class="form-control"      formControlName="name"   (input)="validatePhoneLength(securityDetail.value.whatsappNumber,securityDetail)"  placeholder="Type here">
                          </div>
                        </div>
    
                        <div class="col-lg-3 col-xs-12 col-sm-5">
                          <div class="form-group">
                            <label>Designation*</label>
                            <input type="email" class="form-control"  formControlName="designation"   (input)="validatePhoneLength(securityDetail.value.whatsappNumber,securityDetail)"  placeholder="Type here">
                          </div>
                        </div>
                        <div class="col-lg-3 col-xs-12 col-sm-10 bd-1">
                          <div class="sm-1">
                            <div class="form-group">
                              <label>Number*</label>
                              <input type="text" class="form-control"                                (input)="validatePhoneLength(securityDetail.value.whatsappNumber,securityDetail)"

                              formControlName="whatsappNumber" placeholder="Type here"   *ngIf="workData.status!='Under Validation'">

                              <input type="text" class="form-control" *ngIf="workData.status=='Under Validation'" ng2TelInput  [ng2TelInputOptions]="{
                                initialCountry: securityDetail.value.whatsappNumber? getCode(securityDetail.value.whatsappNumber,securityDetail) : 'ae'
                               
                              }" 
                              (input)="validatePhoneLength(securityDetail.value.whatsappNumber,securityDetail)"
                            (intlTelInputObject)="telInputObject($event)"
                            (onCountryChange)="onCountryChange($event)"

                              formControlName="whatsappNumber" placeholder="Type here"  >

                              <div *ngIf="workData.status=='Under Validation'&&securityDetail.value.check==false&&submitted" style="color: red;font-size: 12px;" >
                               <p>{{ securityDetail.value.check==true?'Valid Data':'Invalid Data  '}}</p> 
                                </div>

                              <div *ngIf="whatsAppContact.status=='INVALID' && (submitted)&&workData.status!='Under Validation'" style="color: red;font-size: 12px;" >
                                <p *ngIf="isPatternError1(whatsAppContact.controls[i])" style="color: red;">Invalid  </p>
                                </div>
                          
                            </div>
                            <div *ngIf="workData.status=='Under Validation'">
                              <button [ngStyle]="{ 'background-color': securityDetail.value.check ? 'green' : '' }" (click)="validatePhoneLength(securityDetail.value.whatsappNumber,securityDetail)" ><img src="../../assets/images/icon.png">
                                <p >{{ securityDetail.value.check==true?'Verified':'Verify  '}}
                                </p>
                              </button>
                             
                            </div>
                          
                            <button style="    margin-left: 59px;background: white;"           (click)="DeleteWhatsapp(i)"><img src="../../assets/images/Delete.png">
                              
                            </button>

                            <button *ngIf="workData.status !== 'New case' && workData.status !== 'Under Validation' && workData.status !== 'Verified'" style="    margin-left: 59px;background: white;"><img src="../../assets/images/ph-vector.png">
                              
                            </button>
    
                          </div>
                        
                          
                        </div>
                     
        
                      
                        </div>
                        </div>
    
                        <div class="audio-one" *ngFor="let item of workData.clientAudio">
                          <audio *ngIf="item" controls >
                            <source [src]="item" type="audio/wav">
                          </audio>
                          <!-- <div class="del-img" (click)="removeAudio(item,i)">
                            <img src="../../assets/images/Delete.png">
                          </div> -->
                        </div> 
    
                        <div class="col-lg-8 col-xs-12 col-sm-6 ext-padding">
                          <div class="col-lg-12 col-xs-12 col-sm-6">
                            <label class="mb-0">Add location</label>
                            <table class="agency-table mt-2" id="ag-t1">
                              <tbody>
                                <tr>
                                  <td class="dot-width">
                                    <img src="../../assets/images/google-map.png">
                                  </td>
                                  <td>
    
                                    <div>
                                      <p class="name-two">Location</p>
                                      <p class="name-one">{{this.clientForm.value.clientGMap?this.clientForm.value.clientGMap.address:''}}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="content">
                                      <button class="btn-two" (click)="showMaps()">Add Google Map</button>
                                    </div>
                                  </td>
                                </tr>
    
                              </tbody>
                            </table>
                          </div>
    
                          <div class="col-lg-12 col-xs-12 col-sm-6">
                            <label>Photos*</label>
                            <div class="form-group img-slide mt-0">
                                <div class="image-upload">
                                    <input type="file" id="fileInputClient" class="file-input-bg-edit"
                                        (change)="upload('clientPhotos',$event)">
                                    <label for="fileInputClient" class="file-label">
                                        <img alt="User Image" src="../../assets/images/no-img.jpg"
                                            class="user-img">
                                        <img src="../../assets/images/plus-black.png" class="plus-img">
                                    </label>
                                </div>
                                <div *ngFor="let data of clientForm.value.clientPhotos" style="cursor: pointer;">
                                    <img [src]="data" style="width: 70px; height: 79px;"  (click)="ImageOpen(data)">
                                </div>
    
    
                            </div>
                          </div>
    
                        </div>
    
    
                        <div class="col-lg-4 col-xs-12 col-sm-6">
                          <div>
<!--     
                            <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="14" style="width: 100%; height: 323px; border: 0px;">
                              <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true" (dragEnd)="markerDragEndBranch($event)">
                    
                              </agm-marker>
                          </agm-map>   -->
                          </div>
                        </div>
    
    
                        <div class="table-container mt-4" *ngIf="workData.status !== 'New case' && workData.status !== 'Under Validation' && workData.status !== 'Verified'">
                          <table class="table-record">
                            <thead>
                              <tr>
                                <th style="width: 2%;"></th>
                                <th style="width: 9%;">Date</th>
                                <th style="width: 16%;">name</th>

                                <th style="width: 60%;">Text</th>
                                <th style="width: 20%;" class="">Call recordings</th>
                              </tr>
                            </thead>
                            <tbody formArrayName="remarks">
                              <tr  *ngFor="let remark of remarks.controls; let i = index" [formGroupName]="i">
                                <td>
                                  <div class="bd-in">{{i+1}} </div>
                                </td>
                                <td>
                                  <div class="bd-in">
                                    <!-- <input type="date" class="form-control" readonly
                                    formControlName="date"> -->
{{format(clientForm.value.remarks[i].date)}}      
                                  
                                  </div>
                                </td>
                                <td>
                                  <div class="bd-in">
                                     <div class="bd-in" style="font-size: 13px;">
                                      <!-- <input type="text" class="form-control" readonly
                                    formControlName="name">  -->
                                    {{clientForm.value.remarks[i].name}} 
                                  
                                  </div>
                                </div>
                                </td>
                                <td>
                                  <div class="bd-in"><textarea rows="3"  style="width: 600px;;;height: 127px;"   [attr.disabled]="clientForm.value.remarks[i].isDisabled ? true : null"
                                    formControlName="remark" ></textarea> </div>
                                </td>

                                <td class="call-recordings">


                                  <button class="add-n"  style="position: relative; overflow: hidden;">
                                    <input
                                    type="file"
                                    (change)="upload(i,$event)"
                                    style="opacity: 0; position: absolute; left: 0; top: 0; width: 100%; height: 100%; cursor: pointer;"
                                  />
                                    Upload
                                  
                                  </button>
                                  <button class="add-n"  (click)="removeItem(i)" *ngIf="!clientForm.value.remarks[i].isDisabled "><img src="../../assets/images/Delete.png"></button>

                                  
                                                                
                                 <div class="audio-one" *ngFor="let item of clientForm.value.remarks[i].audioRecording">
                                    <audio *ngIf="item" controls >
                                      <source [src]="item" type="audio/wav">
                                    </audio>
                                    <div class="del-img" (click)="removeAudio(item,i)">
                                      <img src="../../assets/images/Delete.png">
                                    </div>
                                  </div> 
                                </td>
                              </tr>
                              <tr>
                                <td colspan="5" class="right-align" style="border-top: 1px solid #fff !important;"  >
                                  <button class="btn-plus"  (click)="addRemark()"><img src="../../assets/images/plus.png"></button>
                                </td>


                               
                              </tr>
                            </tbody>
                          </table>
                        </div>
    
    
                        <div class="remark-div"  *ngIf="workData.status !== 'New case' && workData.status !== 'Under Validation' && workData.status !== 'Verified'">
                          <button class="add-remark"
                          (click)="remarkUpdate()"
                          >
                            <!-- <img src="../../assets/images/plus-blue.svg"> -->
                            <p>Save remarks</p>
                          </button>
                        </div>
    
    </div>
    
                    </form>
    
                    <div class="content mt-5">
                      <button class="btn-two" (click)="Allocate('backend')" *ngIf="workData.flag=='EDIT'&&workData.status=='New case'">Allocate</button>
                      <button class="btn-two" (click)="Allocate('tellecaller')" *ngIf="workData.flag=='EDIT'&&workData.status=='Verified'">Allocate</button>
                      <!-- <button class="add-n" (click)="StatusChangemodal=true" *ngIf="workData.flag=='EDIT'&&workData.status!='Under Validation'&&workData.status!='Verified'">Next</button> -->

                      <button class="add-n" (click)="router.navigate(['/work-page'])">Cancel</button>
                      <!---->
                      <button class="btn-two"  *ngIf="workData.status=='New case'||workData.flag=='ADD'" (click)="Save()">Save</button>
                      <button class="btn-two"  *ngIf="workData.status=='Under Validation'" (click)="SaveStatus('Verified')">Verified</button>

                      <button class="btn-two ext-two"  *ngIf="this.workData.flag!='ADD'" (click)="previous()"><img src="../../assets/images/keyboard_double_arrow_left.png" >
                        <p>Send to previous step</p>
                      </button>
                      <button class="btn-two ext-two"  (click)="StatusChangemodal=true" *ngIf="showStatusChangeButton()">
                        <p>Proceed</p><img src="../../assets/images/keyboard_double_arrow_right.png">
                      </button>
                    
                    </div>
    
    
                 
    
                  </div>
                  <div id="show-img" class="overlay" *ngIf="showMap">
                    <div class="col-lg-6" style="background-color: #fff;">
                  
                        <div class="form-group">
                            <input type="text" class="form-control"  (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #agmSearch>
                        </div>
                  
                  
                         <div class="form-group">
                            <input [(ngModel)]="lat1" type="text" placeholder="Latitude" (input)="setLatitude($event)">
                            <input [(ngModel)]="long1" type="text" placeholder="Longitude" (input)="setLongitude($event)">
                          
                          </div>
                          
                         
                  
                        <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="14" style="width: 100%; height: 323px; border: 0px;">
                            <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true" (dragEnd)="markerDragEndBranch($event)">
                  
                            </agm-marker>
                        </agm-map>           
                        <div class="content">
                            <button class="add-n" (click)="ok()">OK</button>
                       </div>
                    </div>
                   
                    
                  </div>
                  
                </div>
    
              </div>
    
            </div>
    
    
          </main>
    
        </div>
      </div>
    
    
    
      <!-- <div class="footer-section2">
    
        <img src="../../assets/images/accruon-footer (2).png">
      </div> -->
</body>
















<div id="" class="overlay" *ngIf="StatusChangemodal">
  <div class="popup-ins" id="popup-ins1">
      <div class="ins-f" id="ins-f1">
      <h2>Status Change</h2>
         
          <form class="staff-form mt-2">
              <div class="row">
                  <div class="col-lg-12 col-xs-12 col-sm-6">
              <div class="form-group">
                 <select class="select-al"               [(ngModel)]="statusChange" 
                 [ngModelOptions]="{standalone:true}"
                 >
                              <option value="Interested" *ngIf="workData.status=='Under TeleCall'">Interested</option>
                              <option value="Field Visit" *ngIf="workData.status=='Interested'"> Field Visit</option>
                                                          <option value="Client Enrolled" *ngIf="workData.status=='Field Visit'&&this.storedData.userType[0].userType=='Manager'" >Client Enrolled</option>              

                              <option value="Not Interested">Not Interested</option>
                              <option value="Reminder">Move To Reminder</option>

                            </select> &nbsp;
                            <input type="date"  class="form-control"   [min]="today"
                            [(ngModel)]="reminderDate"  *ngIf="statusChange=='Reminder'"
                            [ngModelOptions]="{standalone:true}">&nbsp;

                            <input type="time"  class="form-control"  
                            [(ngModel)]="reminderTime"  *ngIf="statusChange=='Reminder'"
                            [ngModelOptions]="{standalone:true}">&nbsp;
<!-- 
                            <input type="text"  class="form-control"  
                            [(ngModel)]="reminderTo"  *ngIf="statusChange=='Reminder'"
                            [ngModelOptions]="{standalone:true}"> -->

&nbsp;

<label *ngIf="statusChange=='Reminder'">ReminderTo</label>
                            <select 
                            *ngIf="statusChange=='Reminder'"
                            class="form-control " 
                            [(ngModel)]="reminderTo" 
                            [ngModelOptions]="{standalone:true}"
                            >
                            <option 
                              *ngFor="let option of telleCallers || []" 
                              [value]="option._id">
                              {{ option.name}}
                            </option>
                          </select>&nbsp;
                          <label *ngIf="statusChange=='Reminder'">ReminderRemark</label>

                          <textarea  *ngIf="statusChange=='Reminder'"
                          class="form-control " 
                          [(ngModel)]="reminderComment" 
                          maxlength="50" 
                          [ngModelOptions]="{standalone:true}"></textarea>
                            <!-- <input type="text"  class="form-control"  
                            [(ngModel)]="reminderTo"  *ngIf="statusChange=='Reminder'"
                            [ngModelOptions]="{standalone:true}"> -->
              </div>
              </div>
          </div>
          </form>
  </div>

      <div class="ins-footer">
  <button class="btn-sec" (click)="StatusChangemodal=false">Back</button>
  <button class="btn-prime" (click)="StatusChange()">Submit</button>
  </div>

  </div>
</div> 

