
<body>
    <div class="sidebar">
        <div class="sidebar-brand">
            <h2>
            <img src="../../assets/images/marketingApp-logo (1).png">
    </h2>
        </div>
        <div class="sidebar-menu">
            <ul>
                <li>
                    <a>
                     <div class="inactive-img" (click)="click='dashboard';route()"  [ngClass]="{ 'active' : click=='dashboard' }">
                        <div>
                            <img src="../../assets/images/dashboard.svg">
                             
                        </div>
                        <span>Dashboard</span>
                    </div>
                    </a>
                </li>
                <li>
                    <a>
                     <div class="inactive-img"   (click)="click='company';route()"  [ngClass]="{ 'active' : click=='company' }">
                        <div><img src="../../assets/images/staff-list.svg" height="34px"></div>
                        <span>Company List</span>
                    </div>
                    </a>
               </li>

               <li>
                <a>
                 <div class="inactive-img"   (click)="click='company';route()"  [ngClass]="{ 'active' : click=='company' }">
                    <div><img src="../../assets/images/staff-list.svg" height="34px"></div>
                    <span>Disabled</span>
                </div>
                </a>
           </li>
               
            </ul>
    
    
    <div class="last-icon">
        <div class="sec-two"  (click)="this.router.navigate(['/works'])">
            <div class="log-div"><img src="../../assets/images/punchin-img.png"></div>
            <p>Punch In</p>
            </div>  
    <div class="sec-one"            (click)="profile()">
    <img [src]="this.storedData.photo?this.storedData.photo:'../../../../assets/images/user1.jpeg'">
    <div class="user-sec">
    <p style="width: 85px;" >{{this.storedData.name}}</p>
    <span>{{this.storedData.userType[0].userType}}</span>
    </div>
    </div>
    <div class="sec-two"  (click)="logOut()">
    <div class="log-div"><img src="../../assets/images/logout.png"></div>
    <p>Logout</p>
    </div>
    </div>
    
    
        </div>
    </div>
    </body>