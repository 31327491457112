import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-draft-files',
  templateUrl: './draft-files.component.html',
  styleUrls: ['./draft-files.component.css']
})
export class DraftFilesComponent implements OnInit {


  currentPage: number = 1;
   AllocationFlag:any=false
   itemsPerPage: number = 25;
   totalItems: number =0
   tableSize: number[] = [5, 10, 20, 50, 100];
   pagingConfig: any = {}
   pageNo: any;
   size: any;
   searchQuery:any=''
   rejectedCases:any=[]
   token:any
   storedData:any
   constructor(private loginService:LoginService,private router:Router) { this.pagingConfig = {
     itemsPerPage: this.itemsPerPage,
     currentPage: this.currentPage,
     totalItems: this.totalItems
   } }
 
   ngOnInit(): void {
     this.token = localStorage.getItem('token');
     this.storedData= JSON.parse(localStorage.getItem("user") || '{}');
    this.listDraftCases()
   }
   onTableSizeChange(event: any): void {
     this.pagingConfig.itemsPerPage = event.target.value;
     this.pagingConfig.currentPage = 1;
     this.size = event.target.value
     this.listDraftCases()
 
   }
   onTableDataChange(event: any) {
     this.pagingConfig.currentPage = event;
     this.listDraftCases()
   }
   listDraftCases(){
     
 
     let req={
       searchKey:this.searchQuery,
 
       page:this.pagingConfig.currentPage,
       limit:this.pagingConfig.itemsPerPage
     }
 
 
     let body={
      status:"Not Interested",

       userType:this.storedData.userType[0].userType,
 
     }
 
 
       this.loginService.listClientsForSupervisor(this.token,req,body)
                 .subscribe((response: any) => {
                   if (response.status === 1) {
                          this.rejectedCases=response.data
                          this.pagingConfig.totalItems = response.data.length;
                         //  this.currentCount=response.count
                         //  this. total=response.totalCount
                   }})
   }
   ClientDetail(flag:any,obj:any){
     obj.flag=flag
     localStorage.setItem('Work',JSON.stringify(obj))
     this.router.navigate(["/add-work-page"])
 
   }
   formatDate(isoDate: string): string {
     if(isoDate){
       const date = new Date(isoDate);
       const day = String(date.getDate()).padStart(2, '0');
       const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
       const year = date.getFullYear();
       return `${day}/${month}/${year}`;
     }else{
       return ''
     }
   
   }
 

}
