import { Component, OnInit,ViewChild,ElementRef,NgZone } from '@angular/core';
import { StaffDashboardService } from '../staff-dashboard/staff-dashboard.service';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { LoginService } from '../login/login.service';
import Swal from 'sweetalert2';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-works',
  templateUrl: './works.component.html',
  styleUrls: ['./works.component.css']
})
export class WorksComponent implements OnInit {
  @ViewChild('dateInput') dateInput!: ElementRef;
  @ViewChild('video') videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvas') canvasElement!: ElementRef<HTMLCanvasElement>;
  videoStream: MediaStream | null = null;



  public mediaRecorder: MediaRecorder | undefined;
  private audioChunks: BlobPart[] = [];
  clientselectionFlag:any=false
  cameraFlag:any=false
  photo: string | null = null;
  punchInForm!: FormGroup
  audioUrl:any
  video:any
  token:any
  storedData:any
  selectedClient:any
  workLogListFlag:any=true
  punchinFlag:any=false
  newWorkDetailFlag:any=false
  punchInUploadFlag:any=false
  selectedDate:any
  work_location:any
  latitude:any
  longitude:any
  zoom:any
  address:any
  workLogArray:any
  cientDetails:any=[]
  private geoCoder: any;
  searchQuery:any=''
  selectedClients:any=[]

  // dateInput:any
 
  constructor(private formBuilder: FormBuilder,private staffDashboardService:StaffDashboardService, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,private loginService :LoginService) { }


  ngOnInit(): void {
    this.token = localStorage.getItem('token');
     this.storedData= JSON.parse(localStorage.getItem("user") || '{}');
      this.setTodayDate();
     this.punchInForm = this.formBuilder.group({
      punchInSelfie:[''],
      punchInRemarks:['']
   
    

    })
    this.listAttendance()

     this.setCurrentLocation()


    this.video = document.createElement('video');
    this.video.width = 640;
    this.video.height = 480;
    // this.listClients()
    
  }
  openClient() {
    console.log('Selected Client Details:',   this.selectedClients);
    // You can now access the details of the selected client here
  }
  toggleClientSelection(client: any, event: Event) {
    const checkbox = event.target as HTMLInputElement;
    
    if (checkbox.checked) {
      // Add client to selectedClients array if checked
      this.selectedClients.push(client._id);
    } else {
      // Remove client from selectedClients array if unchecked
      this.selectedClients = this.selectedClients.filter((c:any) => c !== client);
    }
  }
  
  listClients(){
    // this.loginService.listClients(this.token,'')
    // .subscribe((response: any) => {
    //   if (response.status === 1) {
    //           this.cientDetails =response.data
            
         
  
    //   }
    // })
  
  
    
  }
  
  setTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2); // Add leading zero if needed
    const day = ('0' + today.getDate()).slice(-2); // Add leading zero if needed
    const todayString = `${year}-${month}-${day}`;

    // Set the date input value to today's date
    this.selectedDate = todayString;

  //  let data= {date:this.selectedDate,
  //   userId:this.storedData._id
  // }
  // this.staffDashboardService.listWorkLog(data, this.token)
  // .subscribe({
  //   next: (response: any) => {
  //     console.log(response);

  //     if (response.status === 1) {
  //       this.workLogArray = response.attendance;
  //     }
  //   },
  //   error: (error) => {
  //     if (error.status === 500) {
  //       console.log("Internal Server Error (500)");
  //       this.workLogListFlag = true;
  //       this.punchinFlag = true;
  //     } else {
  //       console.error("An error occurred:", error);
  //     }
  //   }
  // });

  }
  addPunchIn(){
    if(this.workLogListFlag){
      this.punchInForm.value.userId= this.storedData._id
      this.punchInForm.value.punchInTime= new Date().toISOString();
      this.punchInForm.value.date=this.selectedDate
      this.punchInForm.value.punchInLocation={latitude:this.latitude,longitude:this.longitude,address:this.address,locationName:this.address}
  // console.log(this.punchInForm.value);
  
  
      this.staffDashboardService.addPuchIn(this.punchInForm.value, this.token)
      .subscribe((response: any) => {
          if (response.status === 1) {
  Swal.fire("Success")
  this.punchInUploadFlag=false
  this.punchinFlag=false
  this.workLogListFlag=true
  this.setCurrentLocation()
  this.listAttendance()
  
          }else{
            Swal.fire(response.error.message)
  
          }})
    }else{
      this.punchInForm.value.attendanceRefId=this.workLogArray._id
      this.punchInForm.value.userId= this.storedData._id
      this.punchInForm.value.punchOutTime= new Date().toISOString();
      this.punchInForm.value.punchOutSelfie= this.punchInForm.value.punchInSelfie
      this.punchInForm.value.punchOutRemarks= this.punchInForm.value.punchInRemarks

      this.punchInForm.value.date=this.selectedDate
      this.punchInForm.value.punchOutLocation={latitude:this.latitude,longitude:this.longitude,address:this.address,locationName:this.address}
  // console.log(this.punchInForm.value);
  
  
      this.staffDashboardService.addPuchOut(this.punchInForm.value, this.token)
      .subscribe((response: any) => {
          if (response.status === 1) {
  Swal.fire("Success")
  this.punchInUploadFlag=false
  this.punchinFlag=false
  this.workLogListFlag=true
  this.setCurrentLocation()
  this.listAttendance()
  
          }else{
            Swal.fire(response.error.message)
  
          }})
  
    }
   

  }
 
  
  upload(key:any,data:any){
    console.log(data,key);
    
        this.loginService.UploadMultiple(data.target.files, this.token)
        .subscribe((response: any) => {
            if (response.status === 1) {
    
              Swal.fire("Uploaded")
              if (key == "selfie") {
                      this.punchInForm.get('punchInSelfie')?.patchValue(response.data[0]);

    
              }
            
           
            
            
            
            
            
            }})
    
    
    
    
    
    
      }


      listAttendance() {
        const today = new Date();
        const year = today.getFullYear();
        const month = ('0' + (today.getMonth() + 1)).slice(-2); // Add leading zero if needed
        const day = ('0' + today.getDate()).slice(-2); // Add leading zero if needed
        const todayString = `${year}-${month}-${day}`;
    
        // Set the date input value to today's date
        this.selectedDate = todayString;
    
       let data= {date:this.selectedDate,
        userId:this.storedData._id
      }
      this.loginService.listAttendance(data, this.token)
      .subscribe({
        next: (response: any) => {
          console.log(response);
    
          if (response.status === 1) {
            this.workLogArray = response.attendance;

            if(this.workLogArray.punchInTime&&this.workLogArray.punchOutTime)
              this.punchinFlag=false

            }else(this.workLogArray.punchInTime&&!this.workLogArray.punchOutTime)
{
  this.punchinFlag=true
  this.workLogListFlag=false
}            

          
        },
        error: (error) => {
          if (error.status === 500) {
            console.log("Internal Server Error (500)");
            this.punchinFlag=true
            this.workLogListFlag = true;
            // this.punchinFlag = true;
          } else {
            console.error("An error occurred:", error);
          }
        }
      });
    
      }
    
      formatTimeOnly(isoString: string) {
        const date = new Date(isoString);
       let timeOnly = date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        });
        return timeOnly
      }
      private setCurrentLocation() {
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition((position) => {
            console.log(position)
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            this.zoom = 8;
            console.log(this.latitude, this.longitude)
            if (this.work_location) {
              this.latitude = this.work_location.latitude;
              this.longitude = this.work_location.longitude;
            }
            console.log(this.latitude,this.longitude);
            
            this.getAddress(this.latitude, this.longitude);
          });
        }
      }
    
      getAddress(latitude: any, longitude: any) {
        console.log(latitude, longitude)
        //var geocoder = new google.maps.Geocoder();
        // if (this.workOrder.work_location) {
        //   latitude = this.work_location.latitude;
        //   longitude = this.work_location.longitude;
        // }
        this.geoCoder = new google.maps.Geocoder;

    
        this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
          console.log(results);
          console.log(status);
          if (status === 'OK') {
            if (results[0]) {
              this.zoom = 1;
              this.address = results[0].formatted_address;
              console.log(this.address)
              this.work_location = {
                latitude: latitude,
                longitude: longitude,
                address: results[0].formatted_address
              }
    
            
          
            } else {
              window.alert('No results found');
            }
          } else {
            window.alert('Geocoder failed due to: ' + status);
          }
    
        });
      }
    


      uploadPhoto(imageData: string, key: string) {
        // Convert the base64 image to a Blob
        const byteString = atob(imageData.split(',')[1]);
        const mimeString = imageData.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
    
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
    
        const blob = new Blob([ab], { type: mimeString });
    
        // Create a File object from the Blob
        const file = new File([blob], 'photo.png', { type: mimeString });
    console.log("bgjhgjhgj",file);
    
        // Call the upload service
        this.loginService.UploadMultiple([file], this.token).subscribe((response: any) => {
     
          this.punchInForm.get('punchInSelfie')?.patchValue(response.data[0]);
this.photo=''
console.log( this.punchInForm.value);

        })
        }



  startCamera() {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(stream => {
        this.videoStream=stream
        this.videoElement.nativeElement.srcObject = stream;
      })
      .catch(error => {
        console.error("Error accessing camera: ", error);
      });
      this.stopCamera()
  }
  stopCamera() {
    if (this.videoStream) {
      // Stop all tracks in the stream
      this.videoStream.getTracks().forEach(track => track.stop());
      this.videoElement.nativeElement.srcObject = null;
      this.videoStream = null;
  
      // Safely remove and re-add the video element if videoParent exists
      const videoParent = this.videoElement.nativeElement.parentNode;
      if (videoParent) {
        videoParent.removeChild(this.videoElement.nativeElement);
  
        // Re-append the video element after a short delay
        setTimeout(() => {
          videoParent.appendChild(this.videoElement.nativeElement);
        }, 100);
      }
    }
  }
  
  
  takePhoto() {
    const canvas = this.canvasElement.nativeElement;
    const video = this.videoElement.nativeElement;

    const context = canvas.getContext('2d');
    if (context) {
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      this.photo = canvas.toDataURL('image/png');
      this.uploadPhoto(this.photo, "staffLogo"); // Pass the key as needed
      this.stopCamera()
    }
  }


  startRecording() {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      this.mediaRecorder = new MediaRecorder(stream);
      
      this.mediaRecorder.ondataavailable = event => {
        this.audioChunks.push(event.data);
      };
      
      this.mediaRecorder.start();
    });
  }
  playAudio(audioBlob: Blob) {
    // Create a URL for the Blob and set it to the audio element
    this.audioUrl = URL.createObjectURL(audioBlob);
  }
  stopRecording() {
    return new Promise<Blob>((resolve) => {
      if( this.mediaRecorder){

      this.mediaRecorder.onstop = () => {
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
        this.audioChunks = [];
        // console.log("fsf",audioBlob,this.mediaRecorder,this.audioChunks);
        this.loginService.UploadMultiple([audioBlob], this.token)
        .subscribe((response: any) => {
          
        this.audioUrl=response.data[0]
        })
       
        
        resolve(audioBlob);
      };
      this.mediaRecorder.stop();
    }

    });
  }
  








  
}
