import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

     return true
    // const expectedRole = route.data['role']?route.data['role']:'';
    // const currentRole = this.authService.getRole();
  
    // if (currentRole &&expectedRole&& currentRole === expectedRole) {
    //   return true;
    // } else if(!expectedRole){

    //   return true;

    // }
    
    
    // else if (!this.authService.isAuthenticated()) {
    //   this.router.navigate(['/login']);
    //   return false;
    // } else{
    //   return false

    // }
  }
  
  
}
