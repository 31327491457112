import { Component, OnInit,ChangeDetectorRef } from '@angular/core';

import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { LoginService } from '../login/login.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
token:any
productForm!: FormGroup
addProductFlag:any=false
productDetails:any=[]
viewProducts:any=false
selectedImage:any
selectedProductId:any
selectedProductfDetails:any
editProductFlag:any=false
searchQuery:any=''
storedData:any={}
  constructor(private formBuilder: FormBuilder,private loginService:LoginService) { }

  ngOnInit(): void {

    this.token = localStorage.getItem('token');
    this.storedData = JSON.parse(localStorage.getItem("user") || '{}');

    this.listProducts()
    this.productForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required,]],
      
      logo:['',[Validators.required]],
      coverImage:[''],
    

    })

  }


    
  saveImage(path:any){
    this.selectedImage = path;

    
    this.productForm.get('coverImage')?.patchValue(path);
console.log(this.productForm.value);
// this.cdr.detectChanges(); 

  }

  upload(key:any,data:any){
    // console.log(data,key);
    
        this.loginService.UploadMultiple(data.target.files, this.token)
        .subscribe((response: any) => {
            if (response.status === 1) {
    
              Swal.fire("Uploaded")
              if (key == "productLogo") {
                // this.productForm.get('logo')?.patchValue(response.data);
                this.productForm.get('logo')?.patchValue(response.data[0]);

    
              }
            
           
            
            
            
            
            
            }})
    
    
    
    
    
    
      }




      


      productCrud(type:any){
        if(type=='Add'){
        
         if(this.productForm)
           {    
          this.loginService.addProduct(this.token,this.productForm.value)
                  .subscribe((response: any) => {
                    if (response.status&&response.status === 1) {
                      Swal.fire("Success")
                      this.addProductFlag=false
                      this.editProductFlag=false
                      this.viewProducts=false
                  this.productForm.reset()
                      // this.selectProduct(response.data.agencyRefId)
                      
                      // localStorage.setItem("CurentAgencyId",response.data.agencyRefId)
                      this.listProducts()

    
    
                  }else{
                    Swal.fire("Failed")
                    this.listProducts()

    
                  }
                  })
                }else{
                  Swal.fire("Fill The Required")
                }
        }else if(type=='Edit'){
          this.productForm.value.productId=this.selectedProductfDetails._id
          // this.staffForm.value.id=this.selectedStaffData._id
          // localStorage.setItem("CurentAgencyId",this.selectedAgencyDetails._id)
          this.loginService.editProduct(this.token,this.productForm.value)
                  .subscribe((response: any) => {
                    if (response.status&&response.status === 1) {
                      Swal.fire("Success")
                      this.listProducts()
                      this.isSelected(response.data._id)
                      this.selectedProductfDetails=response.data
                      this.editProductFlag=false
                      this.viewProducts=true
                      // this.selectProduct(response.data.agencyRefId)
                      
                      // localStorage.setItem("CurentAgencyId",response.data.agencyRefId)
    
                    
                      
    
                  }else{
                    Swal.fire("Failed")
                    this.listProducts()

    
                  }
                  })
    
    
        }
       
        
    
      }


      isSelected(id: any): boolean {
        // console.log("fgfh",id);
        
        localStorage.setItem("CurentProductId",id)
        return this.selectedProductId === id;
       
      }


      
    editProduct(){

          this.productForm.get('logo')?.patchValue(this.selectedProductfDetails.logo);
          this.productForm.get('coverImage')?.patchValue(this.selectedProductfDetails.coverImage);
          this.productForm.get('name')?.patchValue(this.selectedProductfDetails.name);
          this.productForm.get('description')?.patchValue(this.selectedProductfDetails.description);


          this.viewProducts=false
          this.viewProducts=false
          this.editProductFlag=true

    }




  listProducts(){

    this.loginService.listProducts(this.token)
    .subscribe((response: any) => {
      if (response.status === 1) {
              this.productDetails =response.data
  
            //   this.productDetails  =     [ {
            //     _id:"dzfdf",
              
            //     coverImage: "../../assets/images/2.jpg",
             
            //     name: "jibina",
               
            //     logo: "https://clean-worlds.s3.ap-south-1.amazonaws.com/cleanworld/files/Frame_26095131874.png",
            //     description: "jibina",

            //   }
          
            // ]
            
                if(this.productDetails.length){
                    this.selectedProductId=this.productDetails[0]._id
                    this.selectProduct(this.productDetails[0]._id)
                }
            }


          })
  
        
  }
  disableProduct(){



    this.loginService.disableProduct(this.token,this.selectedProductfDetails._id)
    .subscribe((response: any) => {
      if (response.status === 1) {
        Swal.fire("Success")
        this.selectedProductfDetails={}
        this.productDetails=false
        this.listProducts()
        this.addProductFlag=false
        this.editProductFlag=false
        this.viewProducts=false
        
     }else{
      Swal.fire("Failed")
     }
    })

  }





  selectProduct(id: any) {
    
    this.selectedProductId = id 
    this.selectedProductfDetails = this.productDetails.find((each: any) => {
      return each._id === id;
    });
    
    
 
            // this.loginService.listSingleStaff(this.token, this.selectedProductId)
            // .subscribe((response: any) => {
            //   if (response.status === 1) {
            //     this.selectedProductfDetails=response.AgeacyDetails
              
            //   }
            //   })

    // this.selectedProductfDetails= {
    //   address: "saadasdfsdfsfaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
    //   coverImage: "../../assets/images/2.jpg",
    //   email: "jibina@gmail.co",
    //   name: "jibina",
    //   password: "jibina",
    //   phoneNumber: "jibina3123",
    
    //   logo: "https://clean-worlds.s3.ap-south-1.amazonaws.com/cleanworld/files/Frame_26095131874.png",
    //   userName: "jibina",
    //   userType: "Executive",
    //   whatsAppNumber: "32213213"
    // };

    this.viewProducts=true
    // this.addProductFlag=false
    this.editProductFlag=false
  }
  

}
