
   
    <div class="main-wrapper">
    <div class="main-content">
        <header>

            <div class="head-line">

                <p>Dashboard</p>
            </div>
            <div class="user-wrapper">
                <p>{{storedData.agencyRefId.agencyName}}</p>

                <div>
                    <img class="log1" [src]="this.storedData.agencyRefId.logo?this.storedData.agencyRefId.logo:'../../../../assets/images/user1.jpeg'">
                </div>
            </div>
        </header>
            
            <main class="main-2">

               <div class="container">
                <div class="projects">
                    
                    
                    <div class="two-screen">
<!---sec sidebar-->

<div class="main-side">
    <!-- <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="14" style="width: 100%; height: 323px; border: 0px;">
        <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true" (dragEnd)="markerDragEndBranch($event)">

        </agm-marker>
    </agm-map>    -->


     <div class="footer-section2">

        <img src="../../assets/images/accruon-footer (2).png">
     </div>
    
    </div>


<!---------------->
                 
                        </div>                 
                 
                    </div>
            </div>

    
            </main>
   
    </div>
    </div>

