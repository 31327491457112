<body>


  
    <div class="main-wrapper">
        <div class="main-content">
            <header>

                <div class="head-line">
    
                    <p>Add Fields</p>
                </div>
                <div class="user-wrapper">
                    <p>{{storedData.agencyRefId.agencyName}}</p>
    
                    <div>
                        <img class="log1" [src]="this.storedData.agencyRefId.logo?this.storedData.agencyRefId.logo:'../../../../assets/../../assets/images/user1.jpeg'">
                    </div>
                </div>
            </header>


            <main class="main-2">

                <div class="container">
                  <div class="projects">
        
        
                    <div class="two-screen">
                      <!---sec sidebar-->
        
        
                      <div class="main-side">
                       
        
        
                        <form class="staff-form"  [formGroup]="fieldForm" >
                          <div class="content mt-5" style="color: azure;">
                                
                            <button class="btn-two ext-two" (click)="addField()" >
                                <p style="color: white;">Add Field   <img src="../../assets/images/plus-black.png"></p>
                                <!-- <img src="../../assets/images/plus-black.png"> -->
                              </button>
                            <!---->
                            
                          </div>
                            <div formArrayName="fields">
                                <div *ngFor="let field of fields.controls; let i = index" [formGroupName]="i">

                          
                              <label style="font-size: large;" (click)="removeField(i)"><img src="../../assets/images/Delete.png"></label>


<span style="    margin-right: 859px;
    float: left;"> Field {{i+1}}</span>
                          <div class="row">
                            <div class="col-lg-10 col-xs-12 col-sm-6">
                              <div class="form-group">
                                <label>Field </label>
                                <input type="text" class="form-control" placeholder="Type here"  formControlName="field">
                              </div>
                            </div>
                           
                            <div class="col-lg-6 col-xs-12 col-sm-6">
        
                                <div class="form-group" style="display: grid;">
                                  <label>Type</label>
                                  <select class="select-al"  formControlName="type" >
                                    <!-- <option value="text" selected>Select your option</option> -->
                                    <option value="text">Text</option>
                                    <option value="number">Number</option>
                                    <option value="date">Date</option>
                                    <option value="month">Month</option>
                                    <option value="time">Time</option>
                                    <option value="textcaps">TextCaps</option>

                                    



                                    <option value="select">Select</option>
                                  </select>
                                </div>
                              </div>
                            <div class="col-lg-4 col-xs-12 col-sm-6">
                              <div class="form-group">
                                <label>Display Name</label>
                                <input type="text" class="form-control" formControlName="displayName" placeholder="Type here">
                              </div>
                            </div>

                            <div class="col-lg-6 col-xs-12 col-sm-6"   *ngIf="fieldForm.value.fields[i].type!='select'">
                              <div class="form-group">
                                <label>Charecter Limit</label>
                                <input type="number" class="form-control"  formControlName="characterLimit"   placeholder="Type here">
                              </div>
                            </div>
                            <div class="col-lg-6 col-xs-12 col-sm-6">
                           <div class="form-group"  style="margin-top: 29px;">
                            <label>Required</label>&nbsp;
                            <input type="checkbox"  formControlName="required">
                           </div>
                            
                            </div>
                            <!-- <div 
                             
                            <input type="checkbox">
class="col-lg-6 col-xs-12 col-sm-6">
                              <div class="form-group">
                                <label for="characterLimitCheckbox">Enable Character Limit</label>
                                <input 
                                  type="checkbox" 
                                  id="characterLimitCheckbox" 
                                  class="form-check-input" 
                                  formControlName="requiredCharacterLimit" 
                                  (change)="toggleCharacterLimit(fieldIndex)"
                                />
                              </div>
                            </div>
                            -->
                            <div formArrayName="valueList" *ngIf="fieldForm.value.fields[i].type=='select'">
                            <div class="content mt-5" (click)="addValue(i)">
                                <button class="add-n">Add Value<img src="../../assets/images/plus-black.png">
                                </button>
                                <!---->
                                
                              </div>

                              <div class="col-lg-9 col-xs-12 col-sm-6" *ngFor="let value of getValueList(i).controls; let j = index">
                                <div class="form-group">
                                  <label>value</label>   {{j+1}} &nbsp;                                <button type="button" (click)="removeValue(i, j)"><img src="../../assets/images/Delete.png"></button>

                                  <input type="text" class="form-control" type="text" [formControlName]="j" placeholder="Type here"> 
                             

                                </div>


                              </div>
                        
                     </div>
                     
         
                      </div>
                    </div>
                    </div>
                    <div class="content mt-5" >
                      <button class="btn-two ext-two" (click)="onSubmit()">Submit
                      </button>
                      <button class="add-n">Cancel
                      </button>

                      </div>

        </form>
                    </div>
        
                  </div>
        
                </div>
        
        </div>
              </main>
        

        </div>
    </div>



</body>