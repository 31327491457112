import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient , HttpHeaders, HttpParams} from '@angular/common/http';
import { AppConfig } from '../config/config';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  API_BASE_URL: any = AppConfig.API_BASE_URL;
  constructor(private http: HttpClient) { }

  login(data:any){
    const url = AppConfig.API_BASE_URL + 'user/login';
  
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/x-www-form-urlencoded',
      //  'authorization': 'Bearer '+token
     });
     const options = { headers: headers };
    
     const body = data
     return this.http.post(url, body, options);
  
  
    }

    listAttendance(data:any,token:any){
      const url = AppConfig.API_STAFF_URL + 'listAttendance';
    
      const headers = new HttpHeaders({
        // 'Content-Type': 'application/x-www-form-urlencoded',
         'authorization': 'Bearer '+token
       });
       const options = { headers: headers };
      
       const body = data
       return this.http.post(url, body, options);
    
    
      }


    forgotPassword(data:any){
      const url = AppConfig.API_BASE_URL + 'user/forgotPassword';
    
      const headers = new HttpHeaders({
        // 'Content-Type': 'application/x-www-form-urlencoded',
        //  'authorization': 'Bearer '+token
       });
       const options = { headers: headers };
      
       const body = {
        "email":data
       }
       return this.http.post(url, body, options);
    
    
      }
  
  
    createClient(data:any,token:any){
      const url = AppConfig.API_BASE_URL + 'client/createClient';
    
      const headers = new HttpHeaders({
        // 'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer '+token
       });
       const options = { headers: headers };
      
       const body = data
       return this.http.post(url, body, options);
    
    
      }


      checkClient(data:any,token:any){
        const url = AppConfig.API_BASE_URL + 'client/checkClient';
      
        const headers = new HttpHeaders({
          // 'Content-Type': 'application/x-www-form-urlencoded',
          'authorization': 'Bearer '+token
         });
         const options = { headers: headers };
        
         const body = data
         return this.http.post(url, body, options);
      
      
        }
      changePassword(data:any,token:any){
        const url = AppConfig.API_BASE_URL + 'user/updatePassword';
      
        const headers = new HttpHeaders({
          // 'Content-Type': 'application/x-www-form-urlencoded',
          'authorization': 'Bearer '+token
         });
         const options = { headers: headers };
        
         const body = data
         return this.http.post(url, body, options);
      
      
        }



    getAddress(lat: number, lon: number): Observable<any> {
      const url = `${'https://nominatim.openstreetmap.org/reverse'}?lat=${lat}&lon=${lon}&format=json`;
      return this.http.get<any>(url);
    }

    disableStaff(token:any,staff:any){

      //const url = this.API_BASE_URL + '/getSingleUserData';
         
      const url = AppConfig.API_BASE_URL + 'user/updateUser';
     
      const headers = new HttpHeaders({
       // 'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer '+token
      });
      const options = { headers: headers };
     
      const body =staff
      return this.http.post(url, body, options);
       }
       disableProduct(token:any,productId:any){

        //const url = this.API_BASE_URL + '/getSingleUserData';
           
        const url = AppConfig.API_BASE_URL + 'products/deleteProduct';
       
        const headers = new HttpHeaders({
         // 'Content-Type': 'application/x-www-form-urlencoded',
          'authorization': 'Bearer '+token
        });
        const options = { headers: headers };
       
        const body = {
          productId:productId
        }
        return this.http.post(url, body, options);
         }














    listStaffs(token:any) {
      //const url = this.API_BASE_URL + '/getSingleUserData';
      const url = AppConfig.API_BASE_URL + 'user/listUsersByAgency';

      
  
      const headers = new HttpHeaders({
      //  'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer '+token
      });
      const options = { headers: headers };
  
      const body = {
        // 'pageNo' : pageNo,
        // 'size' : size
      };
  
      
      return this.http.post(url, body, options);
    }
    listStaffs1(token:any,req:any) {
      console.log("7");
      
      //const url = this.API_BASE_URL + '/getSingleUserData';
      const url = AppConfig.API_BASE_URL + 'user/getUserList';

      
  
      const headers = new HttpHeaders({
      //  'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer '+token
      });
      const options = { headers: headers };
  
      const body = req
  
      
      return this.http.post(url, body, options);
    }
    listClients(token:any,data:any,Body:any) {
      //const url = this.API_BASE_URL + '/getSingleUserData';
      const url = AppConfig.API_BASE_URL + 'client/listClients';

      const headers = new HttpHeaders({
        authorization: 'Bearer ' + token
      });
  
      let httpParams = new HttpParams();
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          httpParams = httpParams.append(key, data[key]);
        }
      }
    const body=Body
      const options = {
        headers: headers,
        params: httpParams
      };
    
      return this.http.post(url,body,options);
    }
    ClientSearch(token:any,data:any) {
      //const url = this.API_BASE_URL + '/getSingleUserData';
      const url = AppConfig.API_BASE_URL + 'client/listClients';

      
  
      const headers = new HttpHeaders({
      //  'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer '+token
      });
      const options = { headers: headers };
  
      const body = data
  
      
      return this.http.post(url, body, options);
    }
    listProducts(token:any) {
      //const url = this.API_BASE_URL + '/getSingleUserData';
      const url = AppConfig.API_BASE_URL + 'products/listProducts';

      
  
      const headers = new HttpHeaders({
      //  'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer '+token
      });
      const options = { headers: headers };
  
      const body = {
        // 'pageNo' : pageNo,
        // 'size' : size
      };
  
      
      return this.http.post(url, body, options);
    }
















    listSingleStaff(token:any,staffId:any){

      //const url = this.API_BASE_URL + '/getSingleUserData';
         
      const url = AppConfig.API_BASE_URL + 'user/getUserDetailsById';
     
      const headers = new HttpHeaders({
       // 'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer '+token
      });
      const options = { headers: headers };
     
      const body = {
        userId:staffId
      };
     
      
      return this.http.post(url, body, options);
       }


       listSingleClient(token:any,Id:any){

        //const url = this.API_BASE_URL + '/getSingleUserData';
           
        const url = AppConfig.API_BASE_URL + 'client/getClientDetailsById';
       
        const headers = new HttpHeaders({
         // 'Content-Type': 'application/x-www-form-urlencoded',
          'authorization': 'Bearer '+token
        });
        const options = { headers: headers };
       
        const body = {
          clientId:Id
        };
       
        
        return this.http.post(url, body, options);
         }

         


    UploadSingle(image:File,token:any){
  
      const url = this.API_BASE_URL + '/uploadFile';
    
      var formData = new FormData();
    
      formData.append('file',image, image.name);
      
      const headers = new HttpHeaders({
    
        'authorization': 'Bearer '+token
      });
      headers.append('Content-Type', 'multipart/form-data');
      const options = { headers: headers };
      return this.http.post(url,formData,options);
    }
   UploadMultiple(event:any,token:any){
   const url = this.API_BASE_URL + 'client/uploadFile';
console.log("jjh",event);

   const files = event;
   const formData = new FormData();
 
 
   for (let i = 0; i < files.length; i++) {
     formData.append('files', files[i], files[i].name);
   }
     
   const headers = new HttpHeaders({
 
     'authorization': 'Bearer '+token
   });
   headers.append('Content-Type', 'multipart/form-data');
   const options = { headers: headers };
   return this.http.post(url,formData,options);
 }
 
    listUserType(token:any) {
      //const url = this.API_BASE_URL + '/getSingleUserData';
      
      const url = 'https://marketingapp-suapi.rimstech.in/api/superadmin/user/listusertype';
  
      const headers = new HttpHeaders({
      //  'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer '+token
      });
      const options = { headers: headers };
  
      const body = {
        // 'pageNo' : pageNo,
        // 'size' : size
      };
  
      
      return this.http.post(url, body, options);
    }
  
    logOut(data:any,token:any){
      const url = AppConfig.API_BASE_URL + 'user/logout';
    
      const headers = new HttpHeaders({
        // 'Content-Type': 'application/x-www-form-urlencoded',
         'authorization': 'Bearer '+token
       });
       const options = { headers: headers };
      
       const body = data
       return this.http.post(url, body, options);
    
    
      }
      addStaff(token:any,formData:any){

        //const url = this.API_BASE_URL + '/getSingleUserData';
           
        const url = AppConfig.API_BASE_URL + 'user/createuser';
       
        const headers = new HttpHeaders({
         // 'Content-Type': 'application/x-www-form-urlencoded',
          'authorization': 'Bearer '+token
        });
        const options = { headers: headers };
       
        const body = formData
        return this.http.post(url, body, options);
         }
         addProduct(token:any,formData:any){

          //const url = this.API_BASE_URL + '/getSingleUserData';
             
          const url = AppConfig.API_BASE_URL + 'products/createProduct';
         
          const headers = new HttpHeaders({
           // 'Content-Type': 'application/x-www-form-urlencoded',
            'authorization': 'Bearer '+token
          });
          const options = { headers: headers };
         
          const body = formData
          return this.http.post(url, body, options);
           }

           updateStaff(token:any,formData:any){

            //const url = this.API_BASE_URL + '/getSingleUserData';
               
            const url = AppConfig.API_BASE_URL + 'user/updateUser';
           
            const headers = new HttpHeaders({
             // 'Content-Type': 'application/x-www-form-urlencoded',
              'authorization': 'Bearer '+token
            });
            const options = { headers: headers };
           
            const body = formData
            return this.http.post(url, body, options);
             }

             updateClient(formData:any,token:any){

              //const url = this.API_BASE_URL + '/getSingleUserData';
                 
              const url = AppConfig.API_BASE_URL + 'client/editClient';
             
              const headers = new HttpHeaders({
               // 'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer '+token
              });
              const options = { headers: headers };
             
              const body = formData
              return this.http.post(url, body, options);
               }
  

             editProduct(token:any,formData:any){

              //const url = this.API_BASE_URL + '/getSingleUserData';
                 
              const url = AppConfig.API_BASE_URL + 'products/editProduct';
             
              const headers = new HttpHeaders({
               // 'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer '+token
              });
              const options = { headers: headers };
             
              const body = formData
              return this.http.post(url, body, options);
               }

               ExcelUpload(token:any,file: File){
                const url = this.API_BASE_URL + 'user/excelUpload';
    
                var formData = new FormData();
                formData.append('file', file, file.name); // Use the actual file name
                formData.append('fileNames', 'excelFile'); 
            
                const headers = new HttpHeaders({
            
                  'authorization': 'Bearer '+token
                });
                headers.append('Content-Type', 'multipart/form-data');
                const options = { headers: headers };
                return this.http.post(url,formData,options);
          
              
                 }
                 addClient(token:any,data:any){

                  
              //const url = this.API_BASE_URL + '/getSingleUserData';
                 
              const url = AppConfig.API_BASE_URL + 'client/createClient';
             
              const headers = new HttpHeaders({
               // 'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer '+token
              });
              const options = { headers: headers };
             
              const body = data
              return this.http.post(url, body, options);
                

                 }

                 editClient(token:any,data:any){

                  
                  //const url = this.API_BASE_URL + '/getSingleUserData';
                     
                  const url = AppConfig.API_BASE_URL + 'client/editClient';
                 
                  const headers = new HttpHeaders({
                   // 'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': 'Bearer '+token
                  });
                  const options = { headers: headers };
                 
                  const body = data
                  return this.http.post(url, body, options);
                    
    
                     }
                     DownloadTemplate(token:any){

                  
                      //const url = this.API_BASE_URL + '/getSingleUserData';
                         
                      const url = AppConfig.API_BASE_URL + 'client/downloadTemplate';
                     
                      const headers = new HttpHeaders({
                       // 'Content-Type': 'application/x-www-form-urlencoded',
                        'authorization': 'Bearer '+token
                      });
                      const options = { headers: headers };
                     
                      return this.http.get(url,options);
                        
        
                         }
                         listSingleAgency(token:any,id:any){

                  
                          //const url = this.API_BASE_URL + '/getSingleUserData';
                             
                          const url = AppConfig.API_STAFF_URL + 'agency/getAgencyById'
                         
                          const headers = new HttpHeaders({
                           // 'Content-Type': 'application/x-www-form-urlencoded',
                            'authorization': 'Bearer '+token
                          });
                          const options = { headers: headers };
                         const body={
                          id:id
                         }
                          return this.http.post(url,body,options);
                            
            
                             }
    
                             listStatusCount(token:any){

                  
                              //const url = this.API_BASE_URL + '/getSingleUserData';
                                 
                              const url = AppConfig.API_BASE_URL + 'client/caseCount';
                             
                              const headers = new HttpHeaders({
                               // 'Content-Type': 'application/x-www-form-urlencoded',
                                'authorization': 'Bearer '+token
                              });
                              const options = { headers: headers };
                           
                              return this.http.get(url,options);
                                
                
                                 }
                                 updateActiveStatus(token:any,data:any){

                  
                                  //const url = this.API_BASE_URL + '/getSingleUserData';
                                     
                                  const url = AppConfig.API_BASE_URL + 'client/updateActiveStatus';
                                 
                                  const headers = new HttpHeaders({
                                   // 'Content-Type': 'application/x-www-form-urlencoded',
                                    'authorization': 'Bearer '+token
                                  });
                                  const options = { headers: headers };
                                  const body=data
                                  return this.http.post(url,data,options);
                                    
                    
                                     }
                                     updateFields(token:any,data:any){

                  
                                      //const url = this.API_BASE_URL + '/getSingleUserData';
                                         
                                      const url = AppConfig.API_STAFF_URL + 'agency/updateAgency';
                                     
                                      const headers = new HttpHeaders({
                                       // 'Content-Type': 'application/x-www-form-urlencoded',
                                        'authorization': 'Bearer '+token
                                      });
                                      const options = { headers: headers };
                                      const body=data
                                      return this.http.post(url,data,options);
                                        
                        
                                         }













                                     listDisabledCases(token:any,data:any,Body:any){

                  
                                      //const url = this.API_BASE_URL + '/getSingleUserData';
                                         
                                      const url = AppConfig.API_BASE_URL + 'client/listDisableClients';
                                     
                                    
      const headers = new HttpHeaders({
        authorization: 'Bearer ' + token
      });
  
      let httpParams = new HttpParams();
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          httpParams = httpParams.append(key, data[key]);
        }
      }
    const body=Body
      const options = {
        headers: headers,
        params: httpParams
      };
                                      return this.http.post(url,body,options);
                                        
                        
                                         }




                                         listRejectedCases(token:any,data:any,Body:any){

                  
                                          //const url = this.API_BASE_URL + '/getSingleUserData';
                                             
                                          const url = AppConfig.API_BASE_URL + 'client/listRejectedClients';
                                         
                                        
          const headers = new HttpHeaders({
            authorization: 'Bearer ' + token
          });
      
          let httpParams = new HttpParams();
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              httpParams = httpParams.append(key, data[key]);
            }
          }
        const body=Body
          const options = {
            headers: headers,
            params: httpParams
          };
                                          return this.http.post(url,body,options);
                                            
                            
                                             }

                                             listReminderCases(token:any,data:any,Body:any){

                  
                                              //const url = this.API_BASE_URL + '/getSingleUserData';
                                                 
                                              const url = AppConfig.API_BASE_URL + 'client/reminderList';
                                             
                                            
              const headers = new HttpHeaders({
                authorization: 'Bearer ' + token
              });
          
              let httpParams = new HttpParams();
              for (const key in data) {
                if (data.hasOwnProperty(key)) {
                  httpParams = httpParams.append(key, data[key]);
                }
              }
            const body=Body
              const options = {
                headers: headers,
                params: httpParams
              };
                                              return this.http.post(url,body,options);
                                                
                                
                                                 }
    
    
    
                                         listPuncedBackEndStaffs(token:any,data:any){

                  
                                          //const url = this.API_BASE_URL + '/getSingleUserData';
                                             
                                          const url = AppConfig.API_BASE_URL + 'user/getUserList';
                                         
                                          const headers = new HttpHeaders({
                                           // 'Content-Type': 'application/x-www-form-urlencoded',
                                            'authorization': 'Bearer '+token
                                          });
                                          const options = { headers: headers };
                                          const body=data
                                          return this.http.post(url,body,options);
                                            
                            
                                             }
                                             autoAllocation(token:any,data:any){

                  
                                              //const url = this.API_BASE_URL + '/getSingleUserData';
                                                 
                                              const url = AppConfig.API_BASE_URL + 'client/autoAllocate';
                                             
                                              const headers = new HttpHeaders({
                                               // 'Content-Type': 'application/x-www-form-urlencoded',
                                                'authorization': 'Bearer '+token
                                              });
                                              const options = { headers: headers };
                                              const body=data
                                              return this.http.post(url,body,options);
                                                
                                
                                                 }
    
                                                 updateClientStatus(token:any,data:any){

                  
                                                  //const url = this.API_BASE_URL + '/getSingleUserData';
                                                     
                                                  const url = AppConfig.API_BASE_URL + 'client/updateClientStatus';
                                                 
                                                  const headers = new HttpHeaders({
                                                   // 'Content-Type': 'application/x-www-form-urlencoded',
                                                    'authorization': 'Bearer '+token
                                                  });
                                                  const options = { headers: headers };
                                                  const body=data
                                                  return this.http.post(url,body,options);
                                                    
                                    
                                                     }

                                                     listClientsForSupervisor(token:any,data:any,Body:any) {
                                                      //const url = this.API_BASE_URL + '/getSingleUserData';
                                                      const url = AppConfig.API_BASE_URL + 'client/listClientsForSupervisor';
                                                
                                                      const headers = new HttpHeaders({
                                                        authorization: 'Bearer ' + token
                                                      });
                                                  
                                                      let httpParams = new HttpParams();
                                                      for (const key in data) {
                                                        if (data.hasOwnProperty(key)) {
                                                          httpParams = httpParams.append(key, data[key]);
                                                        }
                                                      }
                                                    const body=Body
                                                      const options = {
                                                        headers: headers,
                                                        params: httpParams
                                                      };
                                                    
                                                      return this.http.post(url,body,options);
                                                    }

                                                    
                                                    previous(token:any,data:any) {
                                                      //const url = this.API_BASE_URL + '/getSingleUserData';
                                                      const url = AppConfig.API_BASE_URL + 'client/previousStage';
                                                
                                                      const headers = new HttpHeaders({
                                                        authorization: 'Bearer ' + token
                                                      });
                                                  
                                                   
                                                      const options = { headers: headers };

                                                    const body=data
                                                     
                                                    
                                                      return this.http.post(url,body,options);
                                                    }



                                                    
}
