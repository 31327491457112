import { Component, OnInit,NgZone,ElementRef,ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, FormArray, EmailValidator } from '@angular/forms';
import Swal from 'sweetalert2';
import { LoginService } from '../login/login.service';
import { MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dynamic-field-crud',
  templateUrl: './dynamic-field-crud.component.html',
  styleUrls: ['./dynamic-field-crud.component.css']
})
export class DynamicFieldCrudComponent implements OnInit {
token:any
storedData:any
fieldForm!: FormGroup
clientQuestion:any=[]
agency:any

  constructor(private fb: FormBuilder,public router:Router,private loginService:LoginService) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    this.storedData = JSON.parse(localStorage.getItem("user") || '{}');
    this.fieldForm = this.fb.group({
      fields: this.fb.array([])
    });
    this.addField()
     this.listSingleAgency()
     
     
  }

  get fields(): FormArray {
    return this.fieldForm.get('fields') as FormArray;
  }

  addField() {
   
    const fieldGroup = this.fb.group({
      field: ['', Validators.required],
      type: ['', Validators.required],
      displayName: ['', Validators.required],
      required: [false],
      characterLimit: [''], // Initially disabled
      value:[''],
      otherValue:[''],
      valueList: this.fb.array([]) // Simple FormArray for values
    });
    this.fields.push(fieldGroup);
  }

  removeField(index: number) {
    this.fields.removeAt(index);
  }
 
  getValueList(fieldIndex: number): FormArray {
    return this.fields.at(fieldIndex).get('valueList') as FormArray;
  }

  addValue(fieldIndex: number) {
    const valueControl = this.fb.control('', Validators.required);
    this.getValueList(fieldIndex).push(valueControl);
  }

  removeValue(fieldIndex: number, valueIndex: number) {
    this.getValueList(fieldIndex).removeAt(valueIndex);
  }

  onSubmit() {
    let data={
      id:this.storedData.agencyRefId._id,
      clientQuestion:this.fieldForm.value.fields
    }

    this.loginService.updateFields(this.token,data)
    .subscribe((response: any) => {
        if (response.status === 1) {
        this.agency=response.data.clientQuestion
        Swal.fire("Success")
        }})
  }

  listSingleAgency(){
    this.loginService.listSingleAgency(this.token,this.storedData.agencyRefId._id)
    .subscribe((response: any) => {
        if (response.status === 1) {
        this.agency=response.data[0].clientQuestion
        if(this.agency.length>0){
          this.setFields(this.agency)
    
         }
        }})

  }
  setFields(fieldsData: any[]) {
    const fieldsArray = this.fieldForm.get('fields') as FormArray;
    fieldsArray.clear(); // Clear existing fields
  
    fieldsData.forEach(field => {
      console.log(field);
      
      const fieldGroup = this.fb.group({
        field: [field.field, Validators.required],
        type: [field.type, Validators.required],
        displayName: [field.displayName, Validators.required],
        required: [field.required],
        characterLimit: [field.characterLimit],
        value:[field.value?field.value:''],
        otherValue:[field.value?field.otherValue:''],
        valueList: this.fb.array(
          field.valueList.map((value:any )=> this.fb.control(value, Validators.required))
        )
      });
      fieldsArray.push(fieldGroup);
    });
  
    console.log('Fields set:', this.fieldForm.value);
  }
  


}
