
<body>
    <div class="main-wrapper">
        <div class="main-content">
            <header>
    
                <div class="head-line">
    
                    <p>Company</p>
                    
    
                </div>
                <div class="user-wrapper">
                    <p>{{storedData.agencyRefId.agencyName}}</p>
    
                    <div>
                        <img class="log1" [src]="this.storedData.agencyRefId.logo?this.storedData.agencyRefId.logo:'../../../../assets/../../assets/images/user1.jpeg'">
                    </div>
                </div>
            </header>
    
    
            <div class="header2">
    
                <a
                *ngFor="let caseType of  this.selectedCases; let i = index"
                class="btn"
                [ngClass]="{ 'active': selectedCase === caseType.name }"
                (click)="selectCase(caseType.name)"
               
              >
                {{ caseType.name }} <span>{{ caseType.count }}</span>
              </a>
    
            </div>
    
            <div class="header3">
    
                <div class="dropdown-container">
                    <label for="rows">Rows</label>
                    <div class="middle-b"></div>
                    <select id="rows"  (change)="onTableSizeChange($event)">
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                    </select>
                </div>
    
    
    
                <div class="fill-sea">
                    <div class="search-m">
                        <input type="text" id="searchQueryInput2" placeholder="Search here..."    [(ngModel)]="searchQuery"  (input)="ListCases()">
                        <button type="submit" id="searchQuerySubmit"><svg viewBox="0 0 24 24"
                                style="width: 24px; height: 24px;">
                                <path fill="#666666"
                                    d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z">
                                </path>
                            </svg>
                        </button>
                    </div>
                    <div class="filter-img-container">
                        <img src="../../assets/images/filter_alt.png" class="filter-img">
    
                    </div>
                </div>
                <a *ngIf="this.storedData.userType[0].userType=='Client Admin'" class="btn" (click)="AllocationFlag=true"><img src="../../assets/images/autorenew.png"> Auto allocate  </a>
                <a  *ngIf="this.storedData.userType[0].userType=='Client Admin'"  class="active btn" >

                    <img src="../../assets/images/vscode-icons_file-type-excel.png">Bulk Upload 
                    <img src="../../assets/images/Up-load.png">
                    <input type="file" class="file-input" (change)="ExcelUpload($event)"  id="fileInputchoose"  accept=".xlsx, .xls">

                </a>
                <a  *ngIf="this.storedData.userType[0].userType=='Client Admin'"   class=" btn"  (click)="DownloadTemplate()">

                     Download Template

                </a>
                
    
            </div>
    
            <main class="main-2">
    
                <div class="container">
                    <div class="projects">
    
    
                        <div class="two-screen">
    
                            <div class="mainside2">
                                <table class="table-main">
                                    <thead>
    
    
                                        <tr>
                                            <th  (click)="sortTable('clientRefId')" style="cursor: pointer;width:6%">Ref. ID <span *ngIf="sortField === 'clientRefId'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>
                                            <th   style="cursor: pointer;width:8%" (click)="sortTable('createdAt')">Initiated date<span *ngIf="sortField === 'createdAt'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>
                                            <th  style="cursor: pointer;width:10%" (click)="sortTable('initiatedBy.name')">Initiated by<span *ngIf="sortField === 'initiatedBy.name'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>
                                            <th  style="cursor: pointer;width:25%" (click)="sortTable('clientName')">Company name<span *ngIf="sortField === 'clientName'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>
                                            <th  style="cursor: pointer;width:8%"  (click)="sortTable('updatedAt')">Updated date<span *ngIf="sortField === 'updatedAt'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>
                                              <th  style="cursor: pointer;width:27%"  (click)="sortTable('products')">Products<span *ngIf="sortField === 'updatedAt'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>
                                            <th class="med-width"  style="cursor: pointer;width:10%"  (click)="sortTable('status')">Status<span *ngIf="sortField === 'status'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>
                                            <th class="right-align" style="width:8%">Actions </th>
                                            <!-- <th class="right-align move-right"><img src="img/Ellipse 92.svg"></th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr    *ngFor="let eachCase of this.showingCases | paginate : pagingConfig; let i = index;  ">
                                            <td style="width:6%">
                                                <p class="name-one">{{eachCase.clientRefId}}</p>
    
                                            </td>
                                            <td style="width:8%">
                                                <p class="name-one">{{ formatDate(eachCase.createdAt)}}</p>
                                            </td>
    
                                            <td style="width:10%">
                                                <p class="name-one">{{eachCase.initiatedBy.name}}</p>
                                            </td>
                                            <td style="width:25%">
                                                <p class="name-one">{{eachCase.clientName}}</p>
                                            </td>
                                            <td style="width:8%">
                                                <p class="name-one">{{ formatDate(eachCase.updatedAt)}}</p>
                                            </td>
                                            <td style="width:27%">

                                                <button class="status-one" *ngFor="let each of eachCase.products; let i = index" style="display: flex;flex-direction: row;">{{ each.name}}</button>
                                                <!-- <p class="name-one">

                                                    <span *ngFor="let each of eachCase.products; let i = index">
                                                      {{ each.name }}<span *ngIf="i < eachCase.products.length - 1">, </span>
                                                    </span>
                                                  </p> -->
                                                                                              </td>
    
                                            <td style="width:10%"><button class="status-one" >{{eachCase.status}} <img
                                                        src="../../assets/images/green-circle.png"></button></td>
                                            <td class="right-align" style="width:8%">
                                                <div class="pop-over" style="cursor: pointer;">
                                                    <img src="../../assets/images/eye-open (1).png"   style="filter: invert(1) brightness(0);"
                                                    (click)="ClientDetail('EDIT',eachCase)">&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <img src="../../assets/images/Delete.png"   style="filter: invert(1) brightness(0);"
                                                    (click)="disable(eachCase)">
                                                </div>
                                            </td>
                                        </tr> 
    
    
                                    
    
    
    
                                     
                                     </tbody>
                               
                                     </table>
                                     <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
                                    </pagination-controls>
                                    <span style="    margin-left: 76px;">{{currentCount }}  Of  {{ total}}</span>

                                     </div>
                                  
                            <!--float button-->
                            <a (click)="ClientDetail('ADD',{})" class="float6">
                                <img src="../../assets/images/plus.png"> Add New Company
                            </a>
    
                            <!-- </div> -->
    
    
                           
                        </div>
    
                    </div>
    
                </div>
    
    
            </main>
    
        </div>
    </div>
    
    <div class="footer-section2">
    
        <img src="../../assets/images/accruon-footer (2).png">
    </div>
    
    </body>
    
      
   <div id="" class="overlay" *ngIf="AllocationFlag">
    <div class="popup-ins" id="popup-ins1">
        <div class="ins-f" id="ins-f1">
        <h2>Auto allocate</h2>
           
            <form class="staff-form mt-2">

                <div class="row">
                <div class="col-lg-12 col-xs-12 col-sm-6">
            <div class="form-group">
                <select id="rows"  [(ngModel)]="autoType"  [ngModelOptions]="{standalone:true}" (change)="this.selectedItems=[]">
                    <!-- <option disabled></option> -->
                    <option value="telle">Tele Callers</option>
                    <option value="backend">Back End Staffs</option>
                  
                </select>
                </div>
                </div>
                </div>






                <div class="row">
                    <div class="col-lg-12 col-xs-12 col-sm-6">
                <div class="form-group">
                  <label>Please Select  Staffs For Auto Allocation </label>
                  <ng-multiselect-dropdown *ngIf="autoType=='backend'" [settings]="dropdownSettings" [data]="PuncedStaffs"  [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedItems"  >
                </ng-multiselect-dropdown>              
            

                <ng-multiselect-dropdown *ngIf="autoType=='telle'" [settings]="dropdownSettings" [data]="PuncedTeleCallerStaffs"  [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedItems"  >
                </ng-multiselect-dropdown>              
            
            
            
              </div>
                </div>
            </div>
            </form>
    </div>
  
        <div class="ins-footer">
    <button class="btn-sec"  (click)="AllocationFlag=false">Back</button>
    <button class="btn-prime"  (click)="allocate()" >Allocate</button>
    </div>
  
    </div>
  </div> 
