import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }
  isAuthenticated(): boolean {
    // Check if the user is authenticated
    // For example, you might check if a token exists in localStorage
    const token = localStorage.getItem('token');
    return !!token;
  }

  getRole(): string | null {

    let storedData = JSON.parse(localStorage.getItem("user") || '{}');
   
      return storedData.userType[0].userType
    

  }

}
