import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.css']
})
export class MainDashboardComponent implements OnInit {
  latitude:any
  longitude:any
  zoom:any
  storedData:any
  constructor() { }

  ngOnInit(): void {
    this.storedData = JSON.parse(localStorage.getItem("user") || '{}');

    this.latitude = 51.678418;
    this.longitude = 7.809007;
     this.zoom = 8;
  }
  markerDragEndBranch($event: any) {
    
    console.log($event);
    console.log('lat', $event.latLng.lat()); //to see the latitude in the console
    console.log('lng', $event.latLng.lng());
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    // this.getAddress(this.latitude, this.longitude);
  }


}
