import { Component,OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  selectedDate:any
  loginForm: FormGroup;
  submitted = false;
  errorMessage = '';
  forgot:any=false
  forgotData:any
  newPasswordFlag=false
  newPassword:any
  show:any = false;
  userTypeStrings:any=[]
  userTypeArrays:any=[]
  constructor(private formBuilder: FormBuilder,private router: Router,private loginService:LoginService) {
    this.loginForm = this.formBuilder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });
  }


  ngOnInit(): void {



  }

  
  

   // Getter for easy access to form fields
   get f() { return this.loginForm.controls; }

   onSubmit() {
     this.submitted = true;
 
     if (this.loginForm.invalid) {
      // Swal.fire("Please Enter Valid Credentials!")
      return console.log("invalid")
    }
   

    this.loginService.login(this.loginForm.value).subscribe((response: any) => {
      if (response) {

    localStorage.setItem('token', response.token);
    // this.router.navigate(["/main-dashboard"])

        if (response.status == 1) {
          Swal.fire({
            title: 'Success!',
            text: 'Your action was successful!',
            icon: 'success',
            confirmButtonText: 'Cool'
          });




          localStorage.setItem('tab','All Cases');
          response.data.userType = response.data.userType.filter((user: any) =>
            ['Client Admin', 'Backend Staff', 'Supervisor', 'Manager', 'Tele Caller'].includes(user.userType)
          );
          
          console.log(response.data.userType);
                    // if(response.data.userType[0].userType=='Client Admin'){
    this.userTypeStrings = response.data.userType.map((user:any) => user.userType);
    this.userTypeArrays = response.data.userType.map((user:any) => user.userType);
    response.data.userTypeStrings=this.userTypeStrings

    response.data.userTypeIds=this.userTypeArrays

    localStorage.setItem("user", JSON.stringify(response.data))
    this.router.navigate(["/work-page"])



          // }else if(response.data.userType[0].userType!=='Client Admin'){
            
            // this.router.navigate(["/work-page"])


          // }



        }

        
        else if (response.status == 2) {
          Swal.fire({
            title: 'Are you sure?',
            text: response.errorDetails + ' , logout all other sessions ? ',
            icon: 'warning',
          })
            .then((willDelete:any) => {

              if (willDelete.isConfirmed == true) {


                let storedData = JSON.parse(localStorage.getItem("user") || '{}');


                let data = {
                  "userId": response.userId || null
                };


              
                this.loginService.logOut(data, "").subscribe((res: any) => {
                  if (res.status == 1) {
                    this.router.navigate(['/login']);
                  }
                });


              }
            })
        }else{
          Swal.fire("Login failed")
        }
  
      
      }
    
    })
    // this.router.navigate(["/main-dashboard"])
  }

    
      

  password() {
    this.show = !this.show;
    // this.className = "fa-eye-slash"
  }

  close(){
    this.forgot = false;
  }





      SendNewPassword(){
        this.loginService.forgotPassword(this.forgotData).subscribe((response: any) => {
          if (response) {
            // if (response.status == 1) {
              Swal.fire(
                response.message
              );
            // }
            
          }})
      }


}
