import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  storedData:any
  constructor() { }

  ngOnInit(): void {
    this.storedData = JSON.parse(localStorage.getItem("user") || '{}');

  }

}
