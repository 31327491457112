import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient , HttpHeaders, HttpParams} from '@angular/common/http';
import { AppConfig } from '../config/config';
@Injectable({
  providedIn: 'root'
})
export class StaffDashboardService {
  API_STAFF_URL: any = AppConfig.API_STAFF_URL;
  constructor(private http: HttpClient) { }

  listWorkLog(data:any,token:any){
    const url = AppConfig.API_STAFF_URL + 'listAttendance';
  
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/x-www-form-urlencoded',
       'authorization': 'Bearer '+token
     });
     const options = { headers: headers };
    
     const body = data
     return this.http.post(url, body, options);
  
  
    }
    addPuchIn(data:any,token:any){
      const url = AppConfig.API_STAFF_URL + 'punchIn';
    
      const headers = new HttpHeaders({
        // 'Content-Type': 'application/x-www-form-urlencoded',
         'authorization': 'Bearer '+token
       });
       const options = { headers: headers };
      
       const body = data
       return this.http.post(url, body, options);
    
    
      }

      addPuchOut(data:any,token:any){
        const url = AppConfig.API_STAFF_URL + 'punchOut';
      
        const headers = new HttpHeaders({
          // 'Content-Type': 'application/x-www-form-urlencoded',
           'authorization': 'Bearer '+token
         });
         const options = { headers: headers };
        
         const body = data
         return this.http.post(url, body, options);
      
      
        }
}
