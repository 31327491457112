import { Component, OnInit,NgZone,ElementRef,ViewChild,} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, FormArray, EmailValidator } from '@angular/forms';
import Swal from 'sweetalert2';
import { LoginService } from '../login/login.service';
import { MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import moment from 'moment';


@Component({
  selector: 'app-add-work-page',
  templateUrl: './add-work-page.component.html',
  styleUrls: ['./add-work-page.component.css']
})
export class AddWorkPageComponent implements OnInit {
@ViewChild("agmSearch") public searchElement!: ElementRef;
  
clientForm!: FormGroup
reminderTime:any
token:any
reminderDate:any
remarkFlag:any=false
audioUrl:any=''
storedData:any
telleCallers:any=[]
emailValidation:any
teleCaller:any
reminderTo:any
telInputInstance:any
isButtonClicked:any=false
userTypesTelleCaller:any={}
workData:any={}
productArray:any=[]
latitude:any
submitted :any=false
showMap:any
longitude:any
staffs:any=[]
long1:any;
productDetails:any=[]
statusChange:any
userTypes:any=[]
// zoom:any
// lat = 51.678418;
// lng = 7.809007;
address:any
StatusChangemodal:any=false
allocateTo:any
countrycode='+971'
lat2:any;
lng2:any
allStaffs:any
lat1:any;
zoom = 10;
reminderComment:any=''
private geoCoder: any;
countryCodeMap = [
  { "code": "+1", "name": "US", "phoneLength": 10 },
  { "code": "+44", "name": "GB", "phoneLength": 10 },
  { "code": "+91", "name": "IN", "phoneLength": 10 },
  { "code": "+61", "name": "AU", "phoneLength": 9 },
  { "code": "+33", "name": "FR", "phoneLength": 9 },
  { "code": "+49", "name": "DE", "phoneLength": 10 },
  { "code": "+81", "name": "JP", "phoneLength": 10 },
  { "code": "+55", "name": "BR", "phoneLength": 11 },
  { "code": "+34", "name": "ES", "phoneLength": 9 },
  { "code": "+39", "name": "IT", "phoneLength": 10 },
  { "code": "+7", "name": "RU", "phoneLength": 10 },
  { "code": "+1-242", "name": "BS", "phoneLength": 10 },
  { "code": "+1-246", "name": "BB", "phoneLength": 10 },
  { "code": "+1-264", "name": "AI", "phoneLength": 10 },
  { "code": "+1-268", "name": "AG", "phoneLength": 10 },
  { "code": "+1-284", "name": "VG", "phoneLength": 10 },
  { "code": "+1-345", "name": "KY", "phoneLength": 10 },
  { "code": "+1-441", "name": "BM", "phoneLength": 10 },
  { "code": "+1-473", "name": "GD", "phoneLength": 10 },
  { "code": "+1-649", "name": "TC", "phoneLength": 10 },
  { "code": "+1-868", "name": "TT", "phoneLength": 10 },
  { "code": "+1-876", "name": "JM", "phoneLength": 10 },
  { "code": "+20", "name": "EG", "phoneLength": 10 },
  { "code": "+30", "name": "GR", "phoneLength": 10 },
  { "code": "+31", "name": "NL", "phoneLength": 9 },
  { "code": "+32", "name": "BE", "phoneLength": 9 },
  { "code": "+36", "name": "HU", "phoneLength": 9 },
  { "code": "+41", "name": "CH", "phoneLength": 9 },
  { "code": "+43", "name": "AT", "phoneLength": 10 },
  { "code": "+45", "name": "DK", "phoneLength": 8 },
  { "code": "+46", "name": "SE", "phoneLength": 9 },
  { "code": "+47", "name": "NO", "phoneLength": 8 },
  { "code": "+48", "name": "PL", "phoneLength": 9 },
  { "code": "+51", "name": "PE", "phoneLength": 9 },
  { "code": "+52", "name": "MX", "phoneLength": 10 },
  { "code": "+53", "name": "CU", "phoneLength": 8 },
  { "code": "+54", "name": "AR", "phoneLength": 10 },
  { "code": "+56", "name": "CL", "phoneLength": 9 },
  { "code": "+57", "name": "CO", "phoneLength": 10 },
  { "code": "+58", "name": "VE", "phoneLength": 10 },
  { "code": "+60", "name": "MY", "phoneLength": 10 },
  { "code": "+62", "name": "ID", "phoneLength": 10 },
  { "code": "+63", "name": "PH", "phoneLength": 10 },
  { "code": "+64", "name": "NZ", "phoneLength": 9 },
  { "code": "+65", "name": "SG", "phoneLength": 8 },
  { "code": "+66", "name": "TH", "phoneLength": 9 },
  { "code": "+82", "name": "KR", "phoneLength": 10 },
  { "code": "+84", "name": "VN", "phoneLength": 10 },
  { "code": "+850", "name": "KP", "phoneLength": 8 },
  { "code": "+852", "name": "HK", "phoneLength": 8 },
  { "code": "+853", "name": "MO", "phoneLength": 8 },
  { "code": "+855", "name": "KH", "phoneLength": 9 },
  { "code": "+856", "name": "LA", "phoneLength": 10 },
  { "code": "+880", "name": "BD", "phoneLength": 10 },
  { "code": "+886", "name": "TW", "phoneLength": 9 },
  { "code": "+90", "name": "TR", "phoneLength": 10 },
  { "code": "+92", "name": "PK", "phoneLength": 10 },
  { "code": "+93", "name": "AF", "phoneLength": 9 },
  { "code": "+94", "name": "LK", "phoneLength": 10 },
  { "code": "+95", "name": "MM", "phoneLength": 9 },
  { "code": "+98", "name": "IR", "phoneLength": 10 },
  { "code": "+212", "name": "MA", "phoneLength": 9 },
  { "code": "+213", "name": "DZ", "phoneLength": 9 },
  { "code": "+216", "name": "TN", "phoneLength": 8 },
  { "code": "+218", "name": "LY", "phoneLength": 9 },
  { "code": "+966", "name": "SA", "phoneLength": 9 },
  { "code": "+971", "name": "AE", "phoneLength": 9 },
  { "code": "+974", "name": "QA", "phoneLength": 8 },
  { "code": "+965", "name": "KW", "phoneLength": 8 },
  { "code": "+973", "name": "BH", "phoneLength": 8 },
  { "code": "+968", "name": "OM", "phoneLength": 8 },
  { "code": "+241", "name": "GA", "phoneLength": 9 },
  { "code": "+254", "name": "KE", "phoneLength": 10 },
  { "code": "+255", "name": "TZ", "phoneLength": 10 }
]
dropdownSettings:any
today:any
ShowFilter:any=false
work_location: any;
private mediaRecorder: MediaRecorder | undefined;
private audioChunks: BlobPart[] = [];

  constructor(private formBuilder: FormBuilder,public router:Router,private loginService:LoginService,private mapsAPILoader: MapsAPILoader,
      private ngZone: NgZone,private cd: ChangeDetectorRef){}
       

  ngOnInit(): void {
    
    this.token = localStorage.getItem('token');
    this.storedData = JSON.parse(localStorage.getItem("user") || '{}');
    const currentDate = new Date();
    this.today = currentDate.toISOString().split('T')[0]; 
    // this.latitude = 51.678418;
    // this.longitude = 7.809007;
    //  this.zoom = 8;
    this.listProducts()
    this.listUserType()
    // this.listStaffs()
   this. listAllStaffs()
    this.latitude = 51.678418;
    this.longitude = 7.809007;
     this.zoom = 8;

     this.openMap()

    // listUserType(){
     
     
    // }
  
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: this.ShowFilter
    };
      console.log('hgh',this.workData);
         this.clientForm = this.formBuilder.group({
            clientLogo:['',[]],
            clientRefId:['',[]],
             initiatedDate:['',[]],
             clientQuestion:[[]],
            initiatedBy:['',[]],
             updatedDate:['',[]],
            allocatedTo:['',[]],
            clientCountry:['UAE',[]],
            clientName: ['', [Validators.required]],
            clientAddress:['',[]],
            companyWebsite:['',[]],
            products:[''],
            email: this.formBuilder.array([ ]), // FormArray for multiple emails
            whatsAppContact: this.formBuilder.array([]) ,
            clientPhotos:[[],[]],
            clientGMap:['',[]],
            remarks: this.formBuilder.array([])




            // clientType: ['', [Validators.required,]],
            
            // clientProductsList:['',[Validators.required]],
            // clientBusinessDetails:['',[Validators.required]],
            
           
            // clientOwnerDetails: this.formBuilder.array([]) ,
      
      
          })
         
      this.workData= JSON.parse(localStorage.getItem("Work") || '{}');

          if( this.workData.flag=='EDIT'){
            console.log(this.workData);
            
            // this.work_location = {
            //   latitude: this.workData.latitude,
            //   longitude: this.workData.longitude,
            //   address:  this.workData.address
            // }
          //       this. openMap()
          //  this. getAddress(this.workData.latitude,this.workData.longitude)
       this.workData.products.map((product: any) => {
        product.item_id=product._id,
        product.item_text=product.name

          });

          this.productArray=this.workData.clientPhotos

          // this.clientForm.get('products')?.patchValue(updatedProducts);
           this. populateWhatsAppDetails(this.workData.whatsAppContact)

          this.populateRemarks(this.workData.remarks)
          this.populateEmail(this.workData.email)
           this.clientForm.get('clientGMap')?.patchValue(this.workData.clientGMap)
           this.clientForm.get('email')?.patchValue(this.workData.email)
           this.clientForm.get('clientLogo')?.patchValue(this.workData.clientLogo)
           this.clientForm.get('clientPhotos')?.patchValue(this.workData.clientPhotos)
           this.clientForm.get('allocatedTo')?.patchValue(this.workData.allocatedTo._id)
           this.clientForm.get('products')?.patchValue(this.workData.products)

           this.teleCaller=this.workData.teleCallerId._id
// mapp multiselect
console.log("dfs",this.productDetails);




// this. openMap()
//           this.getAddress(this.workData.latitude,this.workData.longitude);

              }else{
              this.listSingleAgency()
                this. addEmail()
                this.addWhatsapp()
                
              }
              
          // this. openMap()
        if( this.workData.remarks.length==0) {

          this.addRemark()

        }
  }
 
  get clientEmail() {
    return this.clientForm.get('email') as FormArray;
  }
  addEmail() {
    const email = this.formBuilder.control('', [
       // Makes the email field required
      Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) // Ensures the value is a valid email format
    ]); 
    this.clientEmail.push(email); // Add it to the FormArray
  }
  removeEmail(index: number) {
    (this.clientForm.get('clientEmails') as FormArray).removeAt(index);
  }

  
  get clientWhatsAppDetails() {
    return this.clientForm.get('whatsAppContact') as FormArray;
  }
  onCountryChange(event: any): void {
    console.log("f");
    
      const countryCode = this.telInputInstance.getSelectedCountryData().dialCode;
  
      // Get the current phone number (without country code)
      let currentNumber = this.telInputInstance.getNumber();
  
      // If the number does not already start with the country code and a '-',
      // add it automatically.
      if (currentNumber && !currentNumber.startsWith(`${countryCode}-`)) {
        currentNumber = `${countryCode}-${currentNumber.replace(countryCode, '')}`;
        this.telInputInstance.setNumber(currentNumber);
      }else if( !currentNumber.startsWith(`${countryCode}-`)){

        currentNumber = `${971}-${currentNumber.replace(countryCode, '')}`;
        this.telInputInstance.setNumber(currentNumber);

      }else if(!currentNumber){
        console.log("898");
        
      currentNumber = `${countryCode}-`;
        this.telInputInstance.setNumber(currentNumber);
      }
      else if (!countryCode&&currentNumber){
        console.log("777");
        
        currentNumber = `${971}-${currentNumber.replace(countryCode, '')}`;
        this.telInputInstance.setNumber(currentNumber);

      }
    
  }
  // Function to add a new security detail group
  addWhatsapp() {
    if(this.workData.flag=='ADD'||this.workData.status=='Under Validation'){
      const clientWhatsAppDetails = this.formBuilder.group({
        name:['', Validators.required],
        designation: ['',Validators.required],
        check:[false],
        whatsappNumber:['+971-',  Validators.pattern('^\\+\\d{1,15}$')]
      });
      this.clientWhatsAppDetails.push(clientWhatsAppDetails);

    }



  else{
    const clientWhatsAppDetails = this.formBuilder.group({
      name:['', Validators.required],
      designation: [''],
      whatsappNumber:['', Validators.pattern('^\\+\\d{1,15}$')]
    });
    this.clientWhatsAppDetails.push(clientWhatsAppDetails);

  }
console.log(this.clientForm.value);

  }

    upload(key:any,data:any){
      console.log(data,key);
      
          this.loginService.UploadMultiple(data.target.files, this.token)
          .subscribe((response: any) => {
              if (response.status === 1) {
      
                Swal.fire("Uploaded")
                if (key == "clientLogo") {
                  // this.productForm.get('logo')?.patchValue(response.data);
                  this.clientForm.get('clientLogo')?.patchValue(response.data[0]);
  
      
                }
                else if (key == "clientCoverImage"){
  
                  this.clientForm.get('clientBackground')?.patchValue(response.data[0]);
  
                  
                }
                else if(key=="clientPhotos"){
                // console.log("687686876",response.data.length);
  
                  response.data.map((each:any)=>{
                   
                   this.productArray.push(each)
  
                  })
                  
                  this.clientForm.get('clientPhotos')?.patchValue(this.productArray);
                      console.log(this.clientForm.value,this.productArray);
  
  
                }
                else if(data instanceof Event){
                  const remarksArray = this.clientForm.get('remarks') as FormArray;
                  
                  // Get the specific FormGroup for the remark at index 'i'
                  const remarkGroup = remarksArray.at(key) as FormGroup;
        
                  remarkGroup.get('audioRecording')?.value.push(response.data[0])    
                  this.cd.detectChanges();

    
                  }
                
              
             
              
              
              
              
              
              }})
      
      
      
      
      
      
        }
        openMap() {
    


          console.log("HHHHHHHHHHHH JJJJJJJJJJJJJJJJJ", this.showMap,this.searchElement)
          this.mapsAPILoader.load().then(() => {
            this.setCurrentLocation();
            this.geoCoder = new google.maps.Geocoder;
      
            //   console.log(this.searchElementRef)
            setTimeout(() => {
              console.log("time outttttttttt",this.searchElement)
              let autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement);
              autocomplete.addListener("place_changed", () => {
                this.ngZone.run(() => {
                  //get the place result
                  let place: google.maps.places.PlaceResult = autocomplete.getPlace();
                  console.log(place.geometry)
      
                  //verify result
                  if (place.geometry === undefined || place.geometry === null) {
                    return;
                  }
      
                  if (place.geometry?.location) {
                    this.longitude = place.geometry.location.lng();
                    this.latitude = place.geometry.location.lat();
                  }
                  console.log(this.latitude, this.longitude)
                  this.work_location = {
                    latitude: this.latitude,
                    longitude: this.longitude,
                    address: place.formatted_address
                  }
      
                  console.log(this.work_location)
                  this.zoom = 17;
                });
              });
            }, 5000);
          });
      
        
          // console.log(this.showMap)
        }
      
        private setCurrentLocation() {
          if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
              console.log(position)
              this.latitude = position.coords.latitude;
              this.longitude = position.coords.longitude;
              this.zoom = 8;
              console.log(this.latitude, this.longitude)
              if (this.work_location) {
                this.latitude = this.work_location.latitude;
                this.longitude = this.work_location.longitude;
              }
              this.getAddress(this.latitude, this.longitude);
            });
          }
        }
        getAddress(latitude: any, longitude: any) {
          console.log(latitude, longitude)
          //var geocoder = new google.maps.Geocoder();
          // if (this.workOrder.work_location) {
          //   latitude = this.work_location.latitude;
          //   longitude = this.work_location.longitude;
          // }
          this.geoCoder = new google.maps.Geocoder;
  
      
          this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
            console.log(results);
            console.log(status);
            if (status === 'OK') {
              if (results[0]) {
                this.zoom = 1;
                this.address = results[0].formatted_address;
                console.log(this.address)
                this.work_location = {
                  latitude: latitude,
                  longitude: longitude,
                  address: results[0].formatted_address
                }
      
              
            
              } else {
                window.alert('No results found');
              }
            } else {
              window.alert('Geocoder failed due to: ' + status);
            }
      
          });
        }
      
        markerDragEndBranch($event: any) {
          console.log($event);
          console.log('lat', $event.latLng.lat()); //to see the latitude in the console
          console.log('lng', $event.latLng.lng());
          this.latitude = $event.latLng.lat();
          this.longitude = $event.latLng.lng();
          this.getAddress(this.latitude, this.longitude);
        }
      
    
        setLatitude(event: any,){
          this.latitude = parseFloat(event.target.value);
          this.getAddress(this.latitude, this.longitude);
        }
      
        setLongitude(event: any,){
          this.longitude = parseFloat(event.target.value);
          this.getAddress(this.latitude, this.longitude);
        }
      
        showMaps(){
          this.showMap = true;
          this.openMap();
        }
      
        ok(){
          this.clientForm.get('clientGMap')?.patchValue(this.work_location);
      
          // console.log(this.work_location)
          this.showMap = false;
        }
        Save(){
          console.log("3",this.clientForm.value,this.clientFormControl);
          this.submitted=true
          const hasRequired = this.clientForm.value.clientQuestion.some((question:any) => question.required&&!question.value);

if (this.workData.flag=='ADD') {
  // if(this.clientForm.invalid==false){

if(!hasRequired){
  let arr:any=[]

  if (this.clientForm.value.products) {
    
    this.clientForm.value.products.map((each:any)=>{
      if(each.item_id)
      arr.push (each.item_id)
    })
    
  }
// console.log(arr);

this.clientForm.get("products")?.patchValue(arr)
console.log(this.clientForm.value);
delete this.clientForm.value.allocatedTo
delete  this.clientForm.value.remarks
        
    this.loginService.addClient(this.token,this.clientForm.value)
    .subscribe((response: any) => {
        if (response.status&&response.status === 1) {
  Swal.fire("Success")
     this.router.navigate(['/work-page'])
        }else{
          Swal.fire(response.errorDetails)

        }
      })
}else{
  Swal.fire("Fill the Required")
  
}
 
  // }else{
  //   Swal.fire("Invalid Credentials")
  // }
}


else{


  let arr:any=[]

  if (this.clientForm.value.products) {
    
    this.clientForm.value.products.map((each:any)=>{
      if(each.item_id)
      arr.push (each.item_id)
    })
    
  }
this.clientForm.get("products")?.patchValue(arr)
this.clientForm.get("clientQuestion")?.patchValue(this.workData.clientQuestion)

console.log(this.clientForm.value,this.workData.clientQuestion);

this.clientForm.value._id=this.workData._id
delete  this.clientForm.value.initiatedBy
delete this.clientForm.value.allocatedTo

    this.loginService.editClient(this.token,this.clientForm.value)
    .subscribe((response: any) => {
        if (response.status&&response.status === 1) {
  Swal.fire("Success")
  this.router.navigate(['/work-page'])
  
  
        }else{
          Swal.fire("Failed")

        }
      })
  }



}
listUserType(){
  this.loginService.listUserType(this.token)
  .subscribe((response: any) => {
    if (response.status === 1) {
            this.userTypes =response.data
            this.userTypesTelleCaller =  response.data.filter((userType:any) => userType.userType == 'Tele Caller');

            this.userTypes =   this.userTypes.filter((userType:any) => userType.userType == 'Backend Staff');

console.log("hjkgkj0", this.userTypes );

this.listStaffs()
this.listTelleCaller()
    }
  })
 
}

        
        populateWhatsAppDetails(data: any): void {
          console.log("232322", data);
          const securityDetailsFormArray = this.clientForm.get('whatsAppContact') as FormArray;

      if (this.workData.status=='Under Validation') {
        data.forEach((detail: any) => {
          let formattedNumber = detail.whatsappNumber;

          if (!formattedNumber.startsWith('+971-')) {

            formattedNumber = formattedNumber.replace(/^[^0-9]+/, ''); // Remove invalid characters at the start
            if (!formattedNumber.includes('-')) {
              
              formattedNumber = `+971-${formattedNumber}`; // Prepend country code if missing
            } else {
              if(formattedNumber.startsWith('-')){
                formattedNumber = `+971${formattedNumber.split('-')[1]}`; // Fix incorrect formatting

              }
             else{
              formattedNumber = `+971-${formattedNumber.split('-')[1]}`; // Fix incorrect formatting

             }
            }
          }
          const whatsapp = this.formBuilder.group({
            name:[detail.name
              ,Validators.required],
            designation: [detail.designation,Validators.required],
            check:[false],
            whatsappNumber:[formattedNumber]
          });
    console.log(whatsapp);
    // this.validatePhoneLength(whatsapp)
          securityDetailsFormArray.push(whatsapp);
        });
    
      }else{
        console.log("fghfghfh",data.length);
        
        data.forEach((detail: any) => {
          const whatsapp = this.formBuilder.group({
            name:[detail.name],
            designation: [detail.designation],
            whatsappNumber:[detail.whatsappNumber, Validators.pattern('^\\+\\d{1,15}$')]
          });
    
          securityDetailsFormArray.push(whatsapp);
        });
    
      }
      
      
        }

        getCode(whatsappNumber: string,control:any): string | null {
          if (!whatsappNumber) {
            return null; // Return null if the phone number is not provided
          }
        
          // Split the number by '_'
          const splitNumber = whatsappNumber.split('-');
          
         
          // Return the second part (the local number)
          const country = this.countryCodeMap.find(entry => entry.code === splitNumber[0]);
          console.log(country, splitNumber[1]);
          
          if(country){
            return country.name;

          }else{

            return 'AE'
          }

        }
        
        DeleteWhatsapp(i:any){
          const formArray = this.clientForm.get('whatsAppContact') as FormArray;

          // To remove a specific control by index:
          formArray.removeAt(i);  // 
        }
        DeleteEmail(i:any){
          const formArray = this.clientForm.get('email') as FormArray;

          // To remove a specific control by index:
          formArray.removeAt(i);  // 
        }
      
        populateEmail(data: any[]) {
          const securityDetailsFormArray = this.clientForm.get('email') as FormArray;
        
          data.forEach((email: string) => {
            const emailFormControl = this.formBuilder.control(
              email,
              [  Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)] // Attach validators
            );
            securityDetailsFormArray.push(emailFormControl); // Add the FormControl to the FormArray
          });
        }
        
        setTelInputInstance(instance: any): void {
          this.telInputInstance = instance;
        }
       
  validatePhoneLength(number:any,control:any): boolean {
    console.log(control);
  
    // Step 1: Split the phone number by '-'
    const splitNumber =number.split('-');
    const countryCode = splitNumber[0];  // Country code part (e.g. +1, +971)
    const localNumber = splitNumber[1];  // The number part after the '-'
    const country = this.countryCodeMap.find(entry => entry.code ===countryCode);

    // Step 2: Find the country map entry for the country code
console.log(country,localNumber.length ,country?country.phoneLength:0);

    if (!country) {
      console.log("Country code not recognized.");

      return false; // If country code is not in the map, return false
    }
console.log(isNaN(localNumber))

    // Step 3: Check the length of the phone number after the country code
    if (localNumber.length === country.phoneLength&&/^\d+$/.test(localNumber)&&control.controls.name.status=="VALID"&& control.controls.designation.status=="VALID"){
      // control.value.check=true
      control.patchValue({ check: true });

      console.log("Phone number is valid.");
      return true; // Valid phone number length
    } else {

      control.patchValue({ check: false });

      console.log("Invalid phone number length.");
      return false; // Invalid length
    }
  }
      

        formatDate(isoDate: string): string {
          if(isoDate){
            const date = new Date(isoDate);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
          }else{
            return ''
          }
        
        }

        formatDate1(isoDate: string): string {
          const date = new Date(isoDate);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
        }

        checkTextLength(question: any): void {
          if (question.type === 'text' && question.maxLength) {
            if (question.value.length > question.maxLength) {
              question.error = `Maximum length of ${question.maxLength} characters exceeded.`;
            } else {
              question.error = ""; // Clear error if within limit
            }
          }
        }
        listSingleAgency(){
          this.loginService.listSingleAgency(this.token,this.storedData.agencyRefId._id)
          .subscribe((response: any) => {
              if (response.status === 1) {
              this.clientForm.value.clientQuestion=response.data[0].clientQuestion
              this.clientForm.get("clientQuestion")?.patchValue(response.data[0].clientQuestion)
              }})

        }


        listProducts(){

          this.loginService.listProducts(this.token).subscribe((response: any) => {
            if (response.status === 1) {
              this.productDetails = response.data.map((each: any) => ({
                ...each,
                item_id: each._id,
                item_text: each.name,
              }));
              
              // Move dependent logic here
            
            }
          });
        
              
        }
        onDropdownClose(){
          console.log("fjhghj",this.clientForm.value);
          
// let arr:any=[]
// this.clientForm.value.products.map((each:any)=>{
//   arr.push (each.name)
// })

// this.clientForm.get("products")?.patchValue(arr)
// console.log(this.clientForm.value);

        }

        listAllStaffs(){

          this.loginService.listStaffs(this.token)
          .subscribe((response: any) => {
            if (response.status === 1) {
                    this.allStaffs =response.data

            }})
          }
            

        listStaffs(){

          let req={
            "userType":this.userTypes[0]._id,
            "key":"auto"
            
            
            }
            
    
          this.loginService.listPuncedBackEndStaffs(this.token,req)
          .subscribe((response: any) => {
          if (response.status === 1) {
            // Swal.fire("Success")
            this.staffs =response.result
               
                // this.PuncedStaffs.map((detail: any) => {
    
                //   detail.item_id= detail._id,
                //   detail.item_text= detail.name
                
                // })
              
             }})
            
    
           
       
         
    
    
        }

        listTelleCaller(){
          let req={
            "userType":this.userTypesTelleCaller[0]._id,
            "key":"auto"
            
            
            }
            
    
          this.loginService.listPuncedBackEndStaffs(this.token,req)
          .subscribe((response: any) => {
          if (response.status === 1) {
            // Swal.fire("Success")
            this.telleCallers =response.result
            //  console.log("hfhfgkjh",this.PuncedStaffs);
               
            
              
              }
    }
          )
        }

   

          
       














          AllocateStaff(){
         
            this.allocateTo=this.clientForm.value.allocatedTo
          }
          Allocate(staff:any){
if(this.allocateTo||this.teleCaller){



            if(staff=='backend'){
              let req={
                _id: this.workData._id,
                allocatedTo:this.allocateTo,
                status:"Under Validation"
              }
              this.loginService.editClient(this.token,req)
              .subscribe((response: any) => {
                if (response.status === 1) {
                        this.staffs =response.result
                        Swal.fire('Success')
                        this.router.navigate(["/work-page"])
                }})
            }else if(staff=='tellecaller'){

              let req={
                _id: this.workData._id,
                teleCallerId:this.teleCaller,
                status:"Under TeleCall"
              }
              this.loginService.editClient(this.token,req)
              .subscribe((response: any) => {
                if (response.status === 1) {
                        this.staffs =response.result
                        Swal.fire('Success')
                        this.router.navigate(["/work-page"])
                }})

            }

          }else{
            Swal.fire("Field is Required")
          }
          
          }
               

          
         
        
          telInputObject(obj:any) {
            
            this.telInputInstance = obj;

          }

         
          get clientFormControl() :any{
            return this.clientForm.get('email') as FormArray;
          }
          get whatsAppContact() :any{
            return this.clientForm.get('whatsAppContact') as FormArray;
          }
          test(){
            console.log(this.clientFormControl);
            
          }
       
          isPatternError(control: AbstractControl | null): boolean {
            console.log(control);
            
            return !!control?.errors?.['pattern'] && typeof control.errors['pattern'] === 'object';
          }
          
          isPatternError1(control: AbstractControl | null): boolean {
            // Ensure control is a FormGroup and check for whatsappNumber control
            const whatsappControl = control?.get('whatsappNumber'); 
          
            // Log the error if it exists
            console.log(control);
          
            // Return whether the pattern error exists and is of type 'object'
            return !!whatsappControl?.errors?.['pattern'] && typeof whatsappControl.errors['pattern'] === 'object';
          }

          private validateFormArrays(): boolean {
            let isValid = true;
          
            // Validate WhatsApp numbers
            this.clientWhatsAppDetails.controls.forEach((group) => {
              const whatsappControl = group.get('whatsappNumber');
              const check = group.get('check');
 console.log(check,whatsappControl);

              const pattern = /^\+\d{1,15}$/; // Regular expression for WhatsApp numbers
              if (whatsappControl &&!this.validatePhoneLength(whatsappControl.value,group)) {
                console.log(whatsappControl,!check);
                
                // whatsappControl.setErrors({ invalidFormat: true });
                isValid = false;
                return
              }
            });
          
            // Validate Email addresses
            this.clientEmail.controls.forEach((group) => {
              // const emailControl = group.get('emailAddress');
              const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // Regular expression for emails
              if (group && !pattern.test(group.value)) {
                // emailControl.setErrors({ invalidFormat: true });
                isValid = false;
              }
            });
          
            return isValid;
          }
          
          
          SaveStatus(status:any){
            this.submitted=true
            const hasRequired = this.workData.clientQuestion.some((question:any) => question.required&&!question.value);

            if(this.validateFormArrays()&&!hasRequired){
            this.clientForm.value.clientQuestion =this.workData.clientQuestion
            let arr:any=[]
          
            if (this.clientForm.value.products) {
              
              this.clientForm.value.products.map((each:any)=>{
                if(each.item_id)
                arr.push (each.item_id)
              })
              
            }
          this.clientForm.get("products")?.patchValue(arr)
          this.clientForm.get("clientQuestion")?.patchValue(this.workData.clientQuestion)

          console.log(this.clientForm.value);
          
          this.clientForm.value._id=this.workData._id
          delete  this.clientForm.value.initiatedBy
          delete this.clientForm.value.allocatedTo
          this.clientForm.value.status=status
              this.loginService.editClient(this.token,this.clientForm.value)
              .subscribe((response: any) => {
                  if (response.status&&response.status === 1) {
            Swal.fire("Success")
            this.router.navigate(['/work-page'])
            
            
                  }else{
                    Swal.fire("Failed")
          
                  }
                })
            
              }else{
                Swal.fire("Verify Contact Details")

              }
          }
          windowOpen(url: string, type: string): void {
            // Ensure URL has a protocol
            const completeUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
          
            if (type === 'google') {
              // Open Google search with the URL as query
              const googleSearchUrl = `https://www.google.com/search?q=${encodeURIComponent(url)}`;
              window.open(googleSearchUrl, '_blank');
            } else if (type === 'site') {
              // Open the website directly
              window.open(completeUrl, '_blank');
            }
          }
          
        
          onButtonClick() {
            this.isButtonClicked = true;
            // Perform any other action needed after button click
          }
          
          createRemark(): FormGroup {
            let today: string = new Date().toISOString();

            // 2025-01-16T15:20:03+05:30
            // Format as 'YYYY-MM-DD'

            return this.formBuilder.group({
              date: [today, Validators.required],
              userId:[this.storedData._id],
              name:[this.storedData.name+"-"+this.storedData.userType[0].userType],
              remark: ['', Validators.required],
              audioRecording: this.formBuilder.array([]),
              isDisabled:false

            });
          }
        
          // Get FormArray for remarks
          get remarks(): FormArray {
            return this.clientForm.get('remarks') as FormArray;
          }

          removeItem(index: number) {
            this.remarks.removeAt(index);
          }
          populateRemarks(remarksData: any[]): void {
            // First clear the existing data
            console.log(this.clientForm.value);
            
            // this.remarks.clear(); 

            console.log(this.clientForm.value);

          
            remarksData.forEach((remarkData: any) => {
              const remarkForm = this.createRemark(); // Create the form for each remark
          
              // Populate the form with the data
              remarkForm.patchValue({
                date:remarkData.date, // Default today's date
                userId: remarkData.id || this.storedData._id,
                name: remarkData.name || (this.storedData.name + "-" + this.storedData.userType[0].userType),
                remark: remarkData.remark || '',
                isDisabled:true
              });
              // remarkForm.get('remark')?.disable();

              // If there are audio recordings, populate the FormArray
              const audioRecordingArray = remarkForm.get('audioRecording') as FormArray;
          
              if (remarkData.audioRecording && Array.isArray(remarkData.audioRecording)) {
                remarkData.audioRecording.forEach((audio: string) => {
                  audioRecordingArray.push(this.formBuilder.control(audio));
                });
              }
          
              // Add the populated remarkForm to the remarks FormArray
              this.remarks.push(remarkForm);
            });
          }
          
          // Add a new remark entry to the remarks FormArray
          addRemark(): void {
            this.remarks.push(this.createRemark());
            // console.log(this.clientForm.value);
            
          }
          
          startRecording() {
            navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
              this.mediaRecorder = new MediaRecorder(stream);
              
              this.mediaRecorder.ondataavailable = event => {
                this.audioChunks.push(event.data);
              };
              
              this.mediaRecorder.start();
            });
          }
          playAudio(audioBlob: Blob) {
            // Create a URL for the Blob and set it to the audio element
            this.audioUrl = URL.createObjectURL(audioBlob);
          }
          stopRecording(i:any) {
            return new Promise<Blob>((resolve) => {
              if( this.mediaRecorder){
        
              this.mediaRecorder.onstop = () => {
                const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
                this.audioChunks = [];
                // console.log("fsf",audioBlob,this.mediaRecorder,this.audioChunks);
                this.loginService.UploadMultiple([audioBlob], this.token).subscribe((response: any) => {
                  // Access the remarks FormArray
                  const remarksArray = this.clientForm.get('remarks') as FormArray;
                  
                  // Get the specific FormGroup for the remark at index 'i'
                  const remarkGroup = remarksArray.at(i) as FormGroup;
        
                  remarkGroup.get('audioRecording')?.value.push(response.data[0])
                  this.cd.detectChanges();
                  // resolve(audioBlob);


              })
            }
            this.mediaRecorder.stop();
            
          }
        })


          }

          remarkUpdate(){

            if(this.clientForm?.value?.remarks[this.clientForm.value.remarks.length - 1]?.remark){
              let req={
                _id: this.workData._id,
               'remarks':this.clientForm.value.remarks
              }
              this.loginService.editClient(this.token,req)
              .subscribe((response: any) => {
                  if (response.status&&response.status === 1) {
                   this.remarkFlag=true
            Swal.fire("Success")
            // this.router.navigate(['/work-page'])
            
            
                  }else{
                    Swal.fire("Failed")
          
                  }
                })
            }else {
              Swal.fire("Fill the remarks")

            }
         
          
           

            

          }
          previous(){
            let req={
              clientId: this.workData._id,
             status:this.workData.status
            }
            this.loginService.previous(this.token,req)
            .subscribe((response: any) => {
                if (response.status&&response.status === 1) {
          Swal.fire("Success")
          this.router.navigate(['/work-page'])
          
          
                }else{
                  Swal.fire("Failed")
        
                }
              })
          
           


          }















       removeAudio(i: number, indexToRemove: number): void {
  // Access the FormArray for the specific remark
  const remarksControl = this.clientForm.get('remarks') as FormArray;
  if (remarksControl && remarksControl.at(i)) {
    const audioRecordingArray = remarksControl.at(i).get('audioRecording') as FormArray;
    if (audioRecordingArray) {
      // Remove the item at the specified index
      audioRecordingArray.removeAt(indexToRemove);
    }
  }
}
StatusChange(){

  if(this.statusChange=='Reminder'){
    if(this.reminderDate){
      let c=new Date(this.reminderDate)
let  time=this.reminderTime
      // c.utc;
 // Extract hours and minutes from the time input
 const [hours, minutes] = this.reminderTime.split(':').map(Number);

 // Set the time on the date object
c.setHours(hours, minutes, 0, 0); // Set hours, minutes, seconds, and milliseconds

      let req={
        id: this.workData._id,
        "reminderDate":c.toISOString(),
        // "reminderTo":this.reminderTo,
         "reminderAllocatedTo":this.reminderTo,
         "reminderRemarks":this.reminderComment
    
      }
      console.log(req);
      
      this.loginService.updateClientStatus(this.token,req)
      .subscribe((response: any) => {
          if (response.status&&response.status === 1) {
    Swal.fire("Success")
    this.router.navigate(['/work-page'])
    
    
          }else{
            Swal.fire("Failed")
  
          }
        })

    }else{
      Swal.fire("Fill the required")
    }
  
  }

else{
  let req={
    id: this.workData._id,
  status:this.statusChange
  }

  this.loginService.updateClientStatus(this.token,req)
  .subscribe((response: any) => {
      if (response.status&&response.status === 1) {
Swal.fire("Success")
this.router.navigate(['/work-page'])


      }else{
        Swal.fire("Failed")

      }
    })

}
    


       
          
}

showStatusChangeButton(): boolean {
  return this.workData?.flag === 'EDIT' &&
    this.workData?.status !== 'Under Validation' &&
    this.workData?.status !== 'Verified' &&
    this.workData?.status !== 'New case' &&
    this.workData?.remarks?.length < this.clientForm?.value?.remarks?.length &&
    this.clientForm?.value?.remarks?.length > 0 && // Ensure remarks array exists and has elements
    this.clientForm?.value?.remarks[this.clientForm.value.remarks.length - 1]?.remark &&
    this.clientForm?.value?.remarks.some((remark: any) => remark.userId === this.storedData._id)&&
    this.remarkFlag
    // Check for id
}


ImageOpen(url:any){

  window.open(url, '_blank');
}

format(date:any){
  return moment(date).format('lll');

}
check(){

  let  data=
  {
    clientName:this.clientForm.value.clientName
  }
  this.loginService.checkClient(data,this.token)
  .subscribe((response: any) => {
      if (response.status&&response.status === 1) {
Swal.fire(response.message)
// this.router.navigate(['/work-page'])
// this.clientForm.get('clientName')?.setValue('');


      }else{
        Swal.fire(response.message)

      }

})}
}
