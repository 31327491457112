
<body>
    <div class="main-wrapper">
        <div class="main-content">
            <header>
    
                <div class="head-line">
    
                    <p>Clients</p>
                    
    
                </div>
                <div class="user-wrapper">
                    <p>{{storedData.agencyRefId.agencyName}}</p>
    
                    <div>
                        <img class="log1" [src]="this.storedData.agencyRefId.logo?this.storedData.agencyRefId.logo:'../../../../assets/../../assets/images/user1.jpeg'">
                    </div>
                </div>
            </header>
    
    
            <div class="header2">
                <div class="dropdown-container">
                    <label for="rows">Filter</label>
                    <div class="middle-b"></div>
                <select id="rows"  (change)="statusChange($event)" style="width: 110px;">
                    <option value="Disabled">Disabled</option>
                    <option value="Not Interested">Rejected</option>
                    <option value="Client Enrolled">Client Enrolled</option>

                    
                </select>
                </div>
    
            </div>
    
            <div class="header3">
    
                <div class="dropdown-container">
                    <label for="rows">Rows</label>
                    <div class="middle-b"></div>
                    <select id="rows"  (change)="onTableSizeChange($event)">
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                    </select>
                </div>
    
    
    
                <div class="fill-sea">
                    <div class="search-m">
                        <input type="text" id="searchQueryInput2" placeholder="Search here..."    [(ngModel)]="searchQuery"  (input)="listDisabledCases()">
                        <button type="submit" id="searchQuerySubmit"><svg viewBox="0 0 24 24"
                                style="width: 24px; height: 24px;">
                                <path fill="#666666"
                                    d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z">
                                </path>
                            </svg>
                        </button>
                    </div>
                    <div class="filter-img-container">
                        <img src="../../assets/images/filter_alt.png" class="filter-img">
    
                    </div>
                </div>
               
    
            </div>
    
            <main class="main-2">
    
                <div class="container">
                    <div class="projects">
    
    
                        <div class="two-screen">
    
                            <div class="mainside2">
                                <table class="table-main" *ngIf="!disabled">
                                    <thead>
    
    
                                        <tr>
                                            <th (click)="sortTable('clientRefId')" >Ref. ID  <span *ngIf="sortField === 'clientRefId'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>
                                            <th (click)="sortTable('createdAt')" >Initiated date <span *ngIf="sortField === 'createdAt'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>
                                            <th (click)="sortTable('initiatedBy.name')">Initiated by<span *ngIf="sortField === 'initiatedBy.name'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>
                                            <th  (click)="sortTable('clientName')">Company name<span *ngIf="sortField === 'clientName'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>
                                            <th (click)="sortTable('updatedAt')">Updated date<span *ngIf="sortField === 'updatedAt'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>
                                            <th class="med-width" (click)="sortTable('status')">Status<span *ngIf="sortField === 'status'">
                                                {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                              </span></th>
                                            <th class="right-align" >Action </th>
                                            <!-- <th class="right-align move-right"><img src="img/Ellipse 92.svg"></th> -->
                                        </tr>
                                    </thead>
                                     <tbody>
                                        <tr    *ngFor="let eachCase of this.disabledCases | paginate : pagingConfig; let i = index;  ">
                                            <td>
                                                <p class="name-one">{{eachCase.clientRefId}}</p>
    
                                            </td>
                                            <td>
                                                <p class="name-one">{{ formatDate(eachCase.createdAt)}}</p>
                                            </td>
    
                                            <td>
                                                <p class="name-one">{{eachCase.initiatedBy.name}}</p>
                                            </td>
                                            <td>
                                                <p class="name-one">{{eachCase.clientName}}</p>
                                            </td>
                                            <td>
                                                <p class="name-one">{{ formatDate(eachCase.updatedAt)}}</p>
                                            </td>
    
                                            <td><button class="status-one" >{{eachCase.status}} <img
                                                        src="../../assets/images/green-circle.png"></button></td>
                                            <td class="right-align">
                                                <div class="pop-over" style="cursor: pointer;">
                                                    <img src="../../assets/images/eye-open (1).png"   style="filter: invert(1) brightness(0);"
                                                    (click)="ClientDetail('EDIT',eachCase)">
                                                  <div class="new-content">
                                                     <a  class="dis-col" (click)="enable(eachCase)">Enable</a>
                                                       <a  class="remin-col">Reminder</a>
                                                   </div> 
                                                </div>
                                            </td>
                                        </tr> 
    
    
                                    
    
    
    
                                     
                                     </tbody> 
                                     <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
                                    </pagination-controls>

                                     </table>
                                     <!-- <span style="text-align: right;">{{currentCount }}  Of  {{ total}}</span> -->
                                  
                                     <table class="table-main" *ngIf="disabled">
                                        <thead>
        
        
                                            <tr>
                                                <th (click)="sortTable('clientRefId')" >Ref. ID  <span *ngIf="sortField === 'clientRefId'">
                                                    {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                                  </span></th>
                                                <th (click)="sortTable('createdAt')">Initiated date<span *ngIf="sortField === 'createdAt'">
                                                    {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                                </span>
                                                </th>
                                                <!-- <th>Initiated by</th> -->
                                                <th (click)="sortTable('disabledBy.name')">Disabled by
                                                    <span *ngIf="sortField === 'disabledBy.name'">
                                                        {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                                        </span>
                                                </th>
                                                <th (click)="sortTable('disabledAt')">Disabled date&time


                                                    <span *ngIf="sortField === 'disabledAt'">
                                                        {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                                        </span>
                                                </th>

                                                <th (click)="sortTable('disableReason')">Comment

                                                    <span *ngIf="sortField === 'disableReason'">
                                                        {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                                        </span>
                                                </th>

                                                <th (click)="sortTable('clientName')">Company name

                                                    <span *ngIf="sortField === 'clientName'">
                                                        {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                                        </span>
                                                </th>
                                                <!-- <th>Updated date</th> -->
                                                <th class="med-width" (click)="sortTable('status')">Status
                                                    <span *ngIf="sortField === 'status'">
                                                        {{ sortOrder === 'asc' ? '▲' : '▼' }}
                                                        </span>
                                                </th>
                                                <th class="right-align">Actions </th>
                                                <!-- <th class="right-align move-right"><img src="img/Ellipse 92.svg"></th> -->
                                            </tr>
                                        </thead>
                                         <tbody>
                                            <tr    *ngFor="let eachCase of this.disabledCases | paginate : pagingConfig; let i = index;  ">
                                                <td>
                                                    <p class="name-one">{{eachCase.clientRefId}}</p>
        
                                                </td>
                                                <td>
                                                    <p class="name-one">{{ formatDate(eachCase.createdAt)}}</p>
                                                </td>
        
                                                <!-- <td>
                                                    <p class="name-one">{{eachCase.initiatedBy?eachCase.initiatedBy.name:''}}</p>
                                                </td> -->

                                                <td>
                                                    <p class="name-one">{{eachCase.disabledBy?eachCase.disabledBy.name:''}}</p>
                                                </td>
                                                <td>
                                                    <p class="name-one">{{format(eachCase.disabledAt)}}</p>
                                                </td>

                                                <td >
                                                    <p class="name-one"  style="width: 103px;">{{eachCase.disableReason}}</p> 
                                                    <!-- <textarea [value]="eachCase.disableReason" style="    width: 1px;
    height: 65px;"></textarea> -->
                                                </td>
                                                <td>
                                                    <p class="name-one">{{eachCase.clientName}}</p>
                                                </td>
                                                <!-- <td>
                                                    <p class="name-one">{{ formatDate(eachCase.updatedAt)}}</p>
                                                </td> -->
        
                                                <td><button class="status-one" >{{eachCase.status}} <img
                                                            src="../../assets/images/green-circle.png"></button></td>
                                                <td class="right-align">
                                                    <div class="pop-over" style="cursor: pointer;">
                                                        <img src="../../assets/images/eye-open (1).png"   style="filter: invert(1) brightness(0);"
                                                        (click)="ClientDetail('EDIT',eachCase)">
                                                      <div class="new-content">
                                                         <a  class="dis-col" (click)="enable(eachCase)">Enable</a>
                                                           <a  class="remin-col">Reminder</a>
                                                       </div> 
                                                    </div>
                                                </td>
                                            </tr> 
        
        
                                        
        
        
        
                                         
                                         </tbody> 
                                         <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
                                        </pagination-controls>
                                        <span style="    margin-left: 76px;">{{currentCount }}  Of  {{ total}}</span>

                                         </table>
                                    </div>
                                  
                          
    
    
                           
                        </div>
    
                    </div>
    
                </div>
    
    
            </main>
    
        </div>
    </div>
    
    <div class="footer-section2">
    
        <img src="../../assets/images/accruon-footer (2).png">
    </div>
    
    </body>
    
 
