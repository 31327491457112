<div class="main-wrapper" *ngIf="edit==false">
    <div class="main-content">
        <header>

            <div class="head-line">

                <p>Client List</p>
            </div>
            <div class="user-wrapper">
                <p>{{storedData.agencyRefId.agencyName}}</p>

                <div>
                    <img class="log1" [src]="this.storedData.agencyRefId.logo?this.storedData.agencyRefId.logo:'../../../../assets/images/user1.jpeg'">
                </div>
            </div>
        </header>
            
            <main class="main-2">

               <div class="container">
                <div class="projects">
                    
                    
                    <div class="two-screen">
<!---sec sidebar-->

<div class="main-side">
    <div class="agency-head" [attr.style]="'background-image: url(' + (this.storedData.coverImage ? this.storedData.coverImage : '../../assets/images/Frame 427319013.jpg') + '); background-size: cover;'">
        <img [src]="this.storedData.photo?this.storedData.photo:'../../../../assets/images/user1.jpeg'">
        <div>
            <p>{{storedData.name}}</p>
           
        </div>
     
    </div>

    <table class="agency-table">
        <tr>
            <td>
                <p class="name-two">Address</p>
                <p class="name-one">{{storedData.address}}</p>

            </td>
        </tr>
        <tr>
            <td>
            <p class="name-two">Phone number</p>
                <p class="name-one">{{storedData.phoneNumber}}</p>
                </td>
        </tr>
        <tr>
            <td>
            <p class="name-two">Whatsapp</p>
                <p class="name-one">{{storedData.whatsAppNumber}}</p>
                </td>
        </tr>
        <tr>
            <td>
            <p class="name-two">Email ID</p>
                <p class="name-one">{{storedData.email}}</p>
                </td>
        </tr>
        <tr>
            <td>
            <p class="name-two">User Name</p>
                <p class="name-one">{{storedData.userName}}</p>
                </td>
        </tr>
        <tr>
            <td>
            <p class="name-two">Password</p>
                <p class="name-one">*********</p>
                </td>
        </tr>
        <tr>
            <td>
                <div class="content">
                    <button class="add-n" (click)="newPasswordFlag=true">Change password</button>
                    <!---->
                    <button class="btn-two" (click)="edit=true">Edit details</button>
                </div>   
            </td>
        </tr>
    </table>





  
    
    </div>

</div>
</div>
</div>
</main>
</div>

</div>



<div class="main-wrapper" *ngIf="edit==true">
    <div class="main-content">
        <header>

            <div class="head-line">

                <p>Profile</p>
            </div>
            <div class="user-wrapper">
                <p>{{storedData.agencyRefId.agencyName}}</p>

                <div>
                    <img class="log1" [src]="this.storedData.agencyRefId.logo?this.storedData.agencyRefId.logo:'../../../../assets/images/user1.jpeg'">
                </div>
            </div>
        </header>
            
            <main class="main-2">

               <div class="container">
                <div class="projects">
                    
                    
                    <div class="two-screen">
<!---sec sidebar-->


<div class="main-side">
                          
    
    
    <div class="agency-head" id="ext-agency2"   [attr.style]="'background-image: url(' + (staffForm.value.coverImage ? staffForm.value.coverImage : '../../assets/images/Frame 427319013.jpg') + '); background-size: cover;'">
        <div class="image-upload">
            <input type="file" id="fileInput" class="file-input-bg-edit" (change)="upload('staffLogo',$event)">
            <label for="fileInput" class="file-label">
             <img alt="User Image" src="{{staffForm.value.photo ? staffForm.value.photo:'../../../../assets/images/user-no.jpg'}}">
                <img src="../../assets/images/edit.png" class="plus-b"></label>
    </div>
    
                                    <div class="main-imglist">
                                        <div class="img-list">
                                         <img src="../../assets/images/1.jpg"  (click)="saveImage('../../assets/images/1.jpg')" class="small-icon"  [ngClass]="{ 'active-icon': staffForm.value.coverImage=='../../assets/images/1.jpg'}">
                                         <img src="../../assets/images/2.jpg"  (click)="saveImage('../../assets/images/2.jpg')" class="small-icon"  [ngClass]="{ 'active-icon': staffForm.value.coverImage=='../../assets/images/2.jpg'}">
                                         <img src="../../assets/images/3.jpg"  (click)="saveImage('../../assets/images/3.jpg')"class="small-icon"  [ngClass]="{ 'active-icon': staffForm.value.coverImage=='../../assets/images/3.jpg'}">
                                         <img src="../../assets/images/4.jpg"  (click)="saveImage('../../assets/images/4.jpg')" class="small-icon"  [ngClass]="{ 'active-icon': staffForm.value.coverImage=='../../assets/images/4.jpg'}">
                                         <img src="../../assets/images/5.jpg"  (click)="saveImage('../../assets/images/5.jpg')" class="small-icon"  [ngClass]="{ 'active-icon': staffForm.value.coverImage=='../../assets/images/5.jpg'}">
                                         <img src="../../assets/images/6.jpg"  (click)="saveImage('../../assets/images/6.jpg')" class="small-icon"  [ngClass]="{ 'active-icon': staffForm.value.coverImage=='../../assets/images/6.jpg'}">
                                       </div>
                                       <img src="../../assets/images/close-black.svg" class="close-b">
                                       </div>
                             
                             </div>





    <table class="agency-table">
        <tbody><tr>
            <td>
    <form class="staff-form"  [formGroup]="staffForm">
       
        <div class="row">
            <div class="col-lg-8 col-xs-12 col-sm-6">
        <div class="form-group">
          <label>Name*</label>
          <input type="text" class="form-control" placeholder="Type here" formControlName="name" [(ngModel)]="storedData.name" >
        </div>
        </div>
        <div class="col-lg-4 col-xs-12 col-sm-6">
            <div class="form-group">
              <label>Phone*</label>
              <input type="text" class="form-control" placeholder="Type here" formControlName="phoneNumber"  [(ngModel)]="storedData.phoneNumber">
            </div>
            </div>
            <div class="col-lg-8 col-xs-12 col-sm-6">
                <div class="form-group" style="display: grid;">
                    <label>Roles*</label>
                   
                    <select class="select-al" formControlName="userType" [(ngModel)]="storedData.userType[0]._id">
                      <option  *ngFor="let each of userTypes" [ngValue]="each._id">{{each.userType}}</option>
                     </select>
                  </div>
                </div>
   
                <div class="col-lg-4 col-xs-12 col-sm-6">
                    <div class="form-group">
                      <label>Whatsapp*</label>
                      <input type="text" class="form-control" placeholder="Type here" formControlName="whatsAppNumber" [(ngModel)]="storedData.whatsAppNumber">
                    </div>
                    </div>
                    <div class="col-lg-8 col-xs-12 col-sm-6">
                        <div class="form-group">
                          <label>Address*</label>
                          <textarea class="form-control" placeholder="Type here" style="height: 167px;" formControlName="address" [(ngModel)]="storedData.address"></textarea>
                        
                        </div>
                        </div>
                        <div class="col-lg-4 col-xs-12 col-sm-6 ext-padding">
                            <div class="col-lg-12 col-xs-12 col-sm-6">
                                <div class="form-group">
                                  <label>Email ID*</label>
                                  <input type="text" class="form-control" placeholder="Type here" formControlName="email" [(ngModel)]="storedData.email">
                                </div>
                                </div>
                                <div class="col-lg-12 col-xs-12 col-sm-6">
                                    <div class="form-group">
                                      <label>Username*</label>
                                      <input type="text" class="form-control" placeholder="Type here" formControlName="userName" [(ngModel)]="storedData.userName">
                                    </div>
                                    </div>
                                    <!-- <div class="col-lg-12 col-xs-12 col-sm-6">
                                        <div class="form-group">
                                          <label>Password*</label>
                                          <input type="text" class="form-control" placeholder="Type here">
                                        </div>
                                        </div> -->
                                    </div>
              
            </div>
      </form>
      </td>
      </tr>
      <tr>
      <td>
        <div class="content">
            <button class="add-n" (click)="edit=false;">Cancel</button>
            <!---->
            <button class="btn-two" (click)="update()">Update</button>
        </div>
      </td>
      </tr>
      </tbody>
      </table>





    </div>

       </div>                 
                 
                    </div>
                   
            </div>

    </main>
    </div>
    </div>























<div class="footer-section2" style="    margin-bottom: -81px;">

    <img src="../../assets/images/accruon-footer (2).png">
 </div>
   <!--Popup start -temporary password-->
  
   <div id="" class="overlay" *ngIf="newPasswordFlag">
    <div class="popup-ins" id="popup-ins1">
        <div class="ins-f" id="ins-f1">
        <h2>Change Password</h2>
           
            <form class="staff-form mt-2">
                <div class="row">
                    <div class="col-lg-12 col-xs-12 col-sm-6">
                        <div class="form-group">
                            <label>Enter your old password</label>
                            <input type="text" class="form-control" placeholder="Type here"  [(ngModel)]="oldPassword"  [ngModelOptions]="{standalone: true}">
                          </div>
                <div class="form-group">
                  <label>Enter your new password</label>
                  <input type="text" class="form-control" placeholder="Type here"  [(ngModel)]="newPassword"  [ngModelOptions]="{standalone: true}">
                </div>
                </div>
            </div>
            </form>
    </div>
  
        <div class="ins-footer">
    <button class="btn-sec" (click)="newPasswordFlag=false">Back</button>
    <button class="btn-prime" (click)="submit();newPasswordFlag=false">Submit</button>
    </div>
  
    </div>
  </div> 
 
   
   <!--Popup end-->
